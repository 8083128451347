(function ($) {

    var saveForm = true;
    var _surveyCurrentXHR;

    function doSaveForm() {
        if (saveForm) {
            var _form = $('.survey-answer-form');
            if (_surveyCurrentXHR != undefined) {
                _surveyCurrentXHR.abort();
            }
            _surveyCurrentXHR = $.post(_form.attr('action') + '/save', _form.serialize());
        }
    }

    $(function () {

        if ($("body").hasClass('survey')) {

            $('.survey-answer-form :input').on('change', doSaveForm);

            $(window).on("beforeunload", doSaveForm);

            $('.survey-answer-form').on('submit', function (e) {
                saveForm = false;
            });


            // Checkbox "Je certifie avoir participé à ce voyage" before filling the survey form
            $('.form-confirm-trip input[type="checkbox"]').on('change', function (e) {

                if ($(this).is(':checked')) {
                    $('.form-confirm-trip').parent().fadeOut('fast', function () {
                        $('.wrap-survey-form').fadeIn('fast')
                    });
                } else {

                    $('.wrap-survey-form').fadeOut('fast', function () {
                        $('.form-confirm-trip').fadeIn('fast')
                    });

                }
            });
        }


        // Next Step / Prev Step
        $(document).on('click', '.wrap-survey-form .stepper a, .tab-navigation a, .error-survey-validation a', function (e) {
            e.preventDefault();

            var
                _this = $(this),
                _rel = _this.data('rel'),
                _form = $('.survey-answer-form'),
                _stepper = $('.wrap-survey-form .stepper'),
                _targetTab = _stepper.find('a[data-rel="' + _rel + '"]').parent(),
                _indexLi = _stepper.find('li').index(_targetTab),
                _toTop = $('.wrap-survey-form').offset().top - 85;


            // Change step only if step is not active
            if (!$(_rel).hasClass('active')) {

                // Show tab
                _form.find('.tab').removeClass('active');
                _form.find(_rel).addClass('active');

                // Highlight tab
                _stepper.find('li').removeAttr('class');
                _stepper.find('li:lt(' + _indexLi + ')').addClass('step-done');
                _targetTab.addClass('active');

                // ScrollTo top of the form
                $('html, body').stop(true, true).animate({scrollTop: _toTop}, 500, function () {
                });
            }
        }); // end next/prev step


    });

})(jQuery);

(function ($) {

    // ** Custom Dropdown on forms made by ALPIXEL (ex. Type de voyage sur Homepage, filters on trip-listing page...)
    timerDp = null;
    openedDropdownParent = null;

    /* @ global vars */
    XSWidthMin = 0;
    XSWidthMax = 359;
    SWidthMin = 360;
    SWidthMax = 767;
    MWidthMin = 768;
    MWidthMax = 989;
    LWidthMin = 990;
    LWidthMax = 1199;
    XLWidthMin = 1200;
    currentWindowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    currentWindowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    updateClamped();

    function updateClamped() {
        $('.read-more-text').each(function (i, el) {
            var isClamped = el.scrollHeight > (el.clientHeight + 10);
            if (isClamped) {
                $(el).parent().find('.read-more-trigger').show();
            } else {
                $(el).parent().find('.read-more-trigger').hide();
            }
        });
    }

    var timeoutClamped;
    $(window).on('resize', function () {
        if (timeoutClamped != undefined) {
            clearTimeout(timeoutClamped);
        }
        timeoutClamped = setTimeout(updateClamped, 250);
    });


    // Close all dropdown in header
    _closeDropdown = function (e) {
        $('#main-header a[data-animate-target]').removeClass('active');
        $('#main-header a[data-animate-target]').next().removeClass('shown letopen');
    };

    _closeCurrentDropdown = function () {
        var _form_item = $('body .current-shown-dropdown');

        if (_form_item !== null && openedDropdownParent !== null) {
            _form_item.removeClass('current-shown-dropdown');
            _form_item.removeAttr("style");
            openedDropdownParent.append(_form_item);
            openedDropdownParent.removeClass('shown');
            openedDropdownParent.removeClass('letopen');
            openedDropdownParent = null;
            $('body .current-shown-dropdown-container').remove();
            setTimeout(function () {
                $(window).trigger('dropdown-closed');
            }, 200);
        }
    };

    // Return the current window width
    _getCurrentWindowWidth = function (e) {
        currentWindowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        return currentWindowWidth
    };

    // Show / Hide right arrow for tabs elements
    _showHideTabsArrow = function (e) {
        var scrollcontainer = document.getElementById('scroll-tabs');

        if (scrollcontainer === null) {
            return;
        }

        var hasHorizontalScrollbar = scrollcontainer.scrollWidth > scrollcontainer.clientWidth;

        if (hasHorizontalScrollbar) {
            $('#scroll-tabs').prev().addClass('shown');
        } else {
            $('#scroll-tabs').prev().removeClass('shown');
        }
    };

    // Show Loader on ajax navigation
    _showLoading = function () {
        $('[data-load-ajax]').addClass('is-loading')
        $('.js-close-listing-filters').hide();
        $('.js-loader-listing-filters').css({"display": "block"});
    };

    // Hide Loader when ajax response is done
    _hideLoading = function () {
        $('[data-load-ajax]').removeClass('is-loading')
    };

    // Check if a GET parameter exists in the url
    _findGetParameter = function (parameterName) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");

        for (var i = 0; i < vars.length; i++) {

            var pair = vars[i].split("=");
            if (pair[0] == parameterName) {
                // return pair[1]; // If you want to return the param value
                return true;
            }

        }

        return false;
    }


    /**
     * Example : $('.wrap-plus-minus-btns').inputQtty();
     */
    $.fn.inputQtty = function () {
        var callback = function ($wrap) {
            var $field = $wrap.find('input');

            $field.on('focusout', function (ev) {
                var value = parseInt($field.val()) || 0;

                if (value < 0) {
                    value = 0;
                }

                $field.val(value);
                //$field.trigger('app.qtty-change', value);
            });

            $wrap.on('click', '.js-qtty-plus', function (ev) {
                ev.preventDefault();

                var value = parseInt($field.val()) || 0;

                //$field.trigger('app.qtty-plus.before', value);

                value++;
                $field.val(value);

                //$field.trigger('app.qtty-plus.after', value);
            });

            $wrap.on('click', '.js-qtty-minus', function (ev) {
                ev.preventDefault();

                var value = parseInt($field.val()) || 0;

                //$field.trigger('app.qtty-minus.before', value);

                value--;

                if (value < 0) {
                    value = 0;
                }

                $field.val(value);
                //$field.trigger('app.qtty-minus.after', value);
            });
        };

        $(this).each(function () {
            callback($(this));
        });
    };

    $(function () {

        /*
            * EVENTS LISTENERS
        */
        window.addEventListener('resize', _getCurrentWindowWidth);
        window.addEventListener('touchmove', _closeDropdown);
        window.addEventListener('scroll', _closeDropdown);
        // window.addEventListener('load', function () {
        //     new FastClick(document.body);
        // }, false);
        // resize parallax
        if ($('body.homepage').length) {
            $(window).trigger('resize').trigger('scroll');
        }

        $('.js-trip-listing-filters a').on('click', function (e) {
            $(this).removeAttr('href');
        });

        $('form[name="reservation_option_travellers"]').on('submit', function (e) {
            $("button.one-click-only-button").prop('disabled', true);
        });

        // $('a.one-click-only-button').one('click', function (e) {
        //     var $btn = $(this);
        //     setTimeout(function () {
        //         $btn.addClass('one-click-only-button-disabled');
        //     }, 500);
        // });
        //
        // $(document).on('click', "a.one-click-only-button-disabled", function (e) {
        //     e.preventDefault();
        // });

        /*
            -- @ LINKS @ --
        */
        // External links
        $(document).on('click', 'a[href$=".pdf"], a.external-link', function (e) {
            e.preventDefault();
            window.open($(this).attr("href"));
        });
        // Disabled links
        $('a.noLink, a[href="GOTOLINK"]').on('click', function (e) {
            e.preventDefault();
        });
        // Backtotop links
        $('a.backtotop').on('click', function (e) {
            e.preventDefault();
            $('body,html').animate({scrollTop: 0}, 250, 'swing');
        });
        $('a.gotoanchor').on('click', function (e) {
            e.preventDefault();
            var aTag = $("#" + $(this).attr('data-target'));

            $('body,html').animate({scrollTop: aTag.offset().top}, 250, 'swing');
        });

        $("[data-obfuscate-attribute]").each(function (i, el) {
            $(el).attr(
                $(el).attr('data-obfuscate-attribute'),
                $(el).attr('data-obfuscate-value')
            );
            $(el).removeAttr('data-obfuscate-attribute');
            $(el).removeAttr('data-obfuscate-value');
        });

        $('span.gcd').on('click', function (e) {
            document.location.href = atob($(this).attr('data-target'));
        });

        // Simple SlideUp / slideDown links
        $(document).on('click', 'a[data-slide]', function (e) {
            e.preventDefault();

            var
                _this = $(this),
                _rel = _this.data('slide');

            if ($(_rel).length) {
                _this.toggleClass('is-expanded')
                $(_rel).stop(true, false).slideToggle()
            }
        });

        $(document).one('submit', '#mauticform_inscriptionnl', function (e) {

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'newsletter-success',
            });

            setTimeout(function () {
                $(this).submit();
            }, 500);
        });


        // Read more links on mobile
        $(document).on('click', 'a.js-read-more', function (e) {
            e.preventDefault();

            var _parent = $(this).parent().parent();

            _parent.find('.not-truncated').addClass('shown');
            _parent.find('.truncated').addClass('cc-hidden');

            // Show Tirawa review Answer if exists
            if (_parent.next().hasClass('review-text-answer')) {
                _parent.next().addClass('shown');
            }
        });

        $(document).on('click', 'a.js-fold-read-more', function (e) {
            e.preventDefault();

            var _parent = $(this).parent().parent();

            _parent.find('.not-truncated').removeClass('shown');
            _parent.find('.truncated').removeClass('cc-hidden');
        });


        /*!
            -- @ Modals @ --
        */
        /* Open modal */
        $(document).on('click', '.modal-open', function (e) {
            e.preventDefault();

            var modal = $(this).data('target');
            var selector = '#' + modal;

            if ($(selector).length) {
                $('html,body').addClass('opened-modal');
                $(selector).addClass('active');

                // show hidden text into the modal
                if ($(this).data('texttoshowinmodal')) {
                    var klass = $(this).data('texttoshowinmodal');
                    $(selector).find('' + klass + '').addClass('shown')
                }

                $(selector).trigger('cc.modal.open');
            } else {
                alert('ChuckCSS error : modal with attribute id="' + modal + '" does not exist!');
            }
        });
        /* Close modal */
        $('.modal:not([data-disabled-overlay])')
            .find('.modal-overlay')
            .add('.modal *[data-close-modal]')
            .on('click', function (e) {
                e.preventDefault();

                if ($(this).parents('.modal').hasClass('active'))
                    $(this).parents('.modal').removeClass('active');

                if (!$('.modal.active').length)
                    $('html,body').removeClass('opened-modal');

                // Hide text who must be hidden when the modla is closed
                $(this).parents('.modal').find('[data-hideonclose]').removeClass('shown');
                $(this).parents('.modal').trigger('cc.modal.close');
            });


        // Specific for trip-listing page : Close video modal
        $('#modal-video,#modal-pourquoi-tirawa')
            .on('cc.modal.close', function () {
                var
                    videoFrame = $(this).find('iframe'),
                    videoSrc = videoFrame.attr('src');

                videoFrame.attr('src', videoSrc);
            });


        /*!
            -- @ Message Newsletter in FOOTER @ --
        */
        /* Close Message by clicking on "close" button */
        $(document).on('click', '.newsletter-success .js-close', function (e) {
            e.preventDefault();

            $('.newsletter-success').fadeOut('fast', function () {
                $(this).remove();
            })
        });
        /* Auto-scroll when registration to newsletter */
        if (_findGetParameter('mauticMessage')) {
            var _newsletterPosition = parseInt($('#main-footer').offset().top - 110);
            $('html, body').stop(true, true).animate({scrollTop: _newsletterPosition}, 500, function () {
            });
        }


        /*
         -- @ ANIMATE AFTER LINKS  @ --
            * "Dropdown-like" into Header
        */
        var timerOut = null;

        $(document).on({
            click: function (e) {
                e.preventDefault();

                // console.log('[data-animate-target] click')

                // On mobile : click = hover !
                if (currentWindowWidth <= MWidthMax) {
                    $(this).trigger('mouseenter');
                }
            },
            mouseover: function () {
                // console.log('[data-animate-target] mouseover')

                // Clear/Kill setTimeOut process
                clearTimeout(timerOut);

                var
                    _this = $(this),
                    _header = $("#main-header"),
                    _rel = _this.next();


                // If an active link already exists, kill it and hide the rel shown element
                if ($('a[data-animate-target].active').length) {

                    $('a[data-animate-target].active').each(function (i, el) {
                        var
                            activeLink = $(this),
                            activeRel = activeLink.next();

                        activeLink.removeClass('active');
                        activeRel.removeClass('shown letopen');
                    });
                }

                if (_rel.length) {
                    _header.addClass("white");
                    _this.addClass('active');
                    _rel.addClass('shown')
                }

            },
            mouseleave: function () {
                // console.log('[data-animate-target] mouseleave')

                var
                    _this = $(this),
                    _header = $("#main-header"),
                    _rel = _this.next();

                if (_rel.length) {

                    // Launch setTimeout to close dropdown
                    timerOut = setTimeout(function () {
                        if (!_rel.hasClass('letopen')) {
                            // console.log('mouseleave du <a>')
                            _header.removeClass("white");
                            _this.removeClass('active');
                            _rel.removeClass('shown letopen')
                        }
                    }, 1000);

                }
            }
        }, '*[data-animate-target]');

        // Expanded relative elements bindings
        $(document).on({
            mouseover: function (e) {
                // console.log('[data-targetedby] mouseover')

                var _this = $(this);

                _this.addClass('letopen');
            },
            mouseleave: function (e) {
                // console.log('[data-targetedby] mouseleave')

                var _this = $(this);

                _this.removeClass('letopen shown');
                _this.prev().removeClass('active')
            }
        }, '*[data-targetedby]');


        /*
            -- @ BACKSTRETCH @ --
            * Attach responsive background-images to elements
        */
        $('.backstretch').each(function (i, el) {
            var imgName = $(this).attr('data-img');

            if (typeof imgName !== 'undefined' && imgName.length)
                $(this).backstretch(imgName);
        });

        $("#footer-menu p.subtitle").on('click', function (e) {
            $(this).parent().find('.inside-menu').toggleClass('shown');
        });

        // Dropdown button clik & mouseleave
        $(document).on({
            click: function (e) {
                // e.preventDefault();

                var
                    _span = $(this),
                    _parent = _span.parent();

                //Get offset


                // Clear/Kill setTimeOut process
                clearTimeout(timerDp);

                //Select all select2 items and close them
                $('.select2').each(function (i, el) {
                    if ($('.select2').hasClass('select2-container--open')) {
                        $(this).select2('close');
                    }
                });

                if (!_parent.hasClass('shown')) {
                    _closeCurrentDropdown();
                    _parent.addClass('shown ');
                    openedDropdownParent = _parent;

                    var _form_item = _parent.find('.form-item');
                    _form_item.addClass('current-shown-dropdown');

                    var _container = document.createElement('div');
                    _container.classList.add('current-shown-dropdown-container');
                    $(_container).append(_form_item[0]);

                    //position form_item to above _parent
                    var _offset = _parent.offset();
                    var _height = _parent.height();
                    var _top = _offset.top + _height + 10;
                    $(_container).css('top', _top);
                    $(_container).css('left', _offset.left);
                    _form_item.css('width', _parent.width());

                    $('body').append(_container);
                    _form_item.show();

                    _form_item.find('input').off('change');
                    _form_item.find('input').on('change', function (e) {
                        if (_form_item.find('input:checked').length == 1) {
                            _span.text(_form_item.find('input:checked').parent().text())
                                .addClass('filled');
                        } else if (_form_item.find('input:checked').length == 0) {
                            _span
                                .removeClass('filled')
                                .text(_span.data('label'));
                        } else {
                            _span
                                .addClass('filled')
                                .text(_form_item.find('input:checked').length + ' éléments sélectionnés');
                        }
                    });

                    setTimeout(function () {
                        var dropdownTimeout;
                        $('.current-shown-dropdown-container').on('mouseleave', function (e) {
                            dropdownTimeout = setTimeout(function () {
                                _closeCurrentDropdown();
                                $(_parent).off('mouseleave');
                                $(_parent).off('mouseenter');
                            }, 200);
                        }).on('mouseenter', function (e) {
                            clearTimeout(dropdownTimeout);
                        });

                        $(_parent).on('mouseleave', function (e) {
                            dropdownTimeout = setTimeout(function () {
                                _closeCurrentDropdown();
                                $(_parent).off('mouseleave');
                                $(_parent).off('mouseenter');
                            }, 200);
                        }).on('mouseenter', function (e) {
                            clearTimeout(dropdownTimeout);
                        });
                    }, 300);

                } else {
                    _closeCurrentDropdown();
                    $(_parent).off('mouseleave');
                    $(_parent).off('mouseenter');
                }
            },
            // mouseleave: function () {
            //     var
            //         _span = $(this),
            //         _parent = _span.parent();
            //
            //     // Launch setTimeout to close dropdown
            //     timerDp = setTimeout(function () {
            //         if (!_parent.hasClass('letopen')) {
            //             _parent.removeClass('shown letopen')
            //         }
            //     }, 1000);
            // }
        }, '.form-item.dropdown .label-like');
        // // Dropdown list mouseover & mouseleave
        // $(document).on({
        //     mouseover: function (e) {
        //         var _parent = $(this).parent();
        //
        //         _parent.addClass('letopen');
        //     }
        // }, '.form-item.dropdown .form-item');
        // // Dropdown list mouseover & mouseleave
        // $(document).on({
        //     mouseleave: function (e) {
        //         $('.form-item.dropdown.shown').removeClass('shown')
        //     }
        // }, '.form-item.dropdown.shown');


        // function closeIfClickOutside(e) {
        //     if ($('.current-shown-dropdown-container') && !$(e.target).closest('.current-shown-dropdown-container').length) {
        //         _closeCurrentDropdown();
        //     }
        // }


        /*
            -- @ BOOTSTRAP AFFIX @ --
            * Used in :
                - trip-listing page : "Filtres" btn fixed on mobile, "Filtres" dropdown fixed on desktop
                - trip-detail page : Tabs fixed when scrolling into the detail page
        */


        // trip listing page
        if ($('.country-filters').length) {

            var
                substract = (currentWindowWidth >= LWidthMin) ? 100 : 0,
                wrapper = $('.wrapper'),
                filterBtnTop = $('.country-filters').offset().top - substract;

            // console.log('affix at '+ ($('.country-filters').offset().top - substract)+ ' : '+$('.country-filters').offset().top+' - '+substract)

            // Affix
            wrapper.affix({
                offset: {
                    top: filterBtnTop
                }
            });

            wrapper.on('affixed.bs.affix', function (e) {
                _showHideTabsArrow();
            });
            wrapper.on('affixed-top.bs.affix', function (e) {
                _showHideTabsArrow();
            });

            // If we are on phone, hide the "Filter" button when "Infos text" appears
            if (currentWindowWidth <= MWidthMax) {
                $('.country-infos-top').bind('appear', function (e) {
                    $('.wrapper').removeClass('affix').addClass('affix-top')
                });
            }
        }


        /*
            -- @ TABS @ --
            * Used is :
                - Trip-listing : Tous nos voyages - Présentation pays, etc...
                - Trip Detail : Jour par jour, Détail prix, Extensions, etc...
        */
        if ($('.wrap-tabs').length) {
            _showHideTabsArrow();
            window.addEventListener('resize', _showHideTabsArrow)
        }


        /*
            -- @ ANCHORS IN TIRAWA PAGES @ --
            * Used in :
                - Tirawa "Why Tirawa" page : reassurances links with anchors
        */
        if ($('.pourquoi-tirawa').length) {

            $('.wrap-landscape').css('cursor', 'pointer');

            if ("onhashchange" in window) {

                var hashHandler = function () {

                    var
                        _hashes = {
                            'savoir-faire-expertise': 'why-tirawa-expertise',
                            'independant-responsable': 'why-tirawa-independant',
                            'qualite-juste-prix': 'why-tirawa-quality',
                            'petits-groupes': 'why-tirawa-groups',
                            'guides-experts': 'why-tirawa-experts',
                            'avis-voyageurs': 'why-tirawa-reviews'
                        },
                        _hash = window.location.hash.substring(1);


                    if (!_hash)
                        return;

                    var
                        elementToScrollTo = _hashes[_hash].toLowerCase(),
                        _finalPosition = parseInt($('#' + elementToScrollTo).offset().top - 90);


                    // Scroll
                    $('html, body').stop(true, true).animate({scrollTop: _finalPosition}, 500, function () {
                    });
                };

                // Launch hashHandler and create event Listener
                hashHandler();
                window.addEventListener("hashchange", hashHandler, false);
            }
        }


        /*
         -- @ TippyJS Plugin @ --
         Show Tooltips
        */
        tippy('.tippyjs');


        var xhrShareForm;
        $('#sendMailToAFriend').on('click', function (e) {
            e.preventDefault();
            if (xhrShareForm != undefined) {
                xhrShareForm.abort();
            }
            xhrShareForm = $.get(Routing.generate('share', {url: $(this).attr('data-uri')}), function (data) {
                $('#modal-send-to-a-friend').find('.js-wrap-form').html(data.html);
            });
        });

        $(document).on('submit', '#modal-send-to-a-friend form', function (e) {
            e.preventDefault();

            $('#modal-send-to-a-friend form button').addClass('cc-loading');

            xhrDownloadForm = $.post($(this).attr('action'), $(this).serialize(), function (data) {

                $('#modal-send-to-a-friend form button').removeClass('cc-loading');

                if (data.submitted === false) {
                    $('#modal-send-to-a-friend').find('.js-wrap-form').html(data.html);
                } else {
                    $('#modal-send-to-a-friend [data-close-modal]').trigger('click');
                    addFlash('success', 'L\'email a bien été envoyé à votre ami');
                }
            });
        });


        /*
            -- @ Custom trips @ --
        */
        $(document).on('click', '.js-deploy-all', function (e) {
            e.preventDefault();

            // Hide link
            $(this)
                .removeClass('js-deploy-all')
                .addClass('js-close-all')
                .find('p')
                .html('Refermer les<br />destinations<br />sur-mesure')

            // Show all countries
            $('.item-custom-trip.item-hidden').addClass('item-shown');
        })
        $(document).on('click', '.js-close-all', function (e) {
            e.preventDefault();

            // Hide link
            $(this)
                .removeClass('js-close-all')
                .addClass('js-deploy-all')
                .find('p')
                .html('Toutes nos<br/>destinations<br/>sur-mesure')

            // Show all countries
            $('.item-custom-trip.item-hidden').removeClass('item-shown');
        })
    });

    function addFlash(type, msg) {
        $('.messages-container').append('<div class="alert alert-' + type + '">' + msg + '</div>');
    }
})(jQuery);


(($) => {
    $(() => {
        let urlContactAgency = Routing.generate('contact_agency');
        let xhr = null;
        let $modal = $('#modal-contact');

        let loadForm = (callback) => {
            if (xhr !== null) {
                xhr.abort();
            }

            xhr = $.get(urlContactAgency)
                .done(data => callback(null, data.content))
                .fail(error => callback(error, null));
        };

        $(document).on('click', "a[data-target='modal-contact']", ev => {
            loadForm((error, data) => {
                if (error) {
                    swalertCommonError();
                    return;
                }


                fillModal(data);

                // Auto-fill Country when clicking on flag
                var button = $(ev.currentTarget);
                if (typeof button.data('lang') !== 'undefined') {
                    var lang = button.data('lang');

                    // Check country
                    $modal.find('#' + lang).prop('checked', 'checked')
                    $modal.find('#' + lang).prop('checked', true)
                }
            });
        });

        let fillModal = (data) => {
            $modal.find('.js-wrap-form').html(data);


            // Show TippyJs errors
            $('.js-wrap-form .alert-error').show();
            setTimeout(
                () => tippy('.js-wrap-form .tippyjs'),
                500
            );
        };


        let onSubmit = (error, data) => {

            $modal.find('.form-actions button').removeClass('cc-loading');

            if (error || data.hasOwnProperty('success') && !data.success) {
                $modal.removeClass('active');
                swalertCommonError();
                return;
            }

            if (data.hasOwnProperty('content')) {
                fillModal(data.content);
                return;
            }

            var formSubject = "Tirawa France";
            if($("#contact_agency_form_agency_2").is(':checked')){
                formSubject = "Tirawa Suisse";
            }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'contact-form-success',
                formSubject: formSubject
            });

            $modal.removeClass('active');
            swalert('success', 'Merci !', data.message);
        };

        $(document).on('submit', '#modal-contact form', ev => {
            ev.preventDefault();

            $modal.find('.form-actions button').addClass('cc-loading');


            if (xhr !== null) {
                xhr.abort();
            }

            const $ctx = $(ev.currentTarget);

            xhr = $.post({
                url: urlContactAgency,
                data: $ctx.serialize(),
            })
                .done(data => onSubmit(null, data))
                .fail(error => onSubmit(error, null));
        });
    });
})(jQuery);


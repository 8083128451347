(($) => {
    $(() => {
        let $action = $('.js-favorite-trip');

        if ($action.length === 0) {
            return;
        }

        let xhr = null;
        let doAction = ($element, action) => {

            if (xhr !== null) {
                xhr.abort();
            }

            xhr = $.get(Routing.generate('customer_favorite_trip_action', {
                "trip": $element.attr('data-trip-id'),
                "action": action
            }))
                .done(response => {
                    if (!response.success) {
                        swalert('error', 'Erreur', response.message);
                        return;
                    }

                    // Add : show success tooltip + show red heart
                    if (action === 'add') {
                        $element.addClass('active success');

                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            event: 'add-to-fav-success',
                            product_name: $element.attr('data-trip-name'),
                            product_id: $element.attr('data-trip-reference'),
                            product_category: $element.attr('data-trip-category'),
                            product_country: $element.attr('data-trip-country')
                        });

                        // Show success message
                        setTimeout(function () {
                            $element.removeClass('success');
                        }, 1500);
                    }

                    // Remove : show gray heart
                    if (action === 'remove') {
                        $element.removeClass('active');
                    }
                })
                .fail(error => {
                    swalertCommonError();
                });
        };

        $action.on('click', ev => {
            let $element = $(ev.currentTarget);
            let isActive = $element.hasClass('active');

            doAction($element, isActive ? 'remove' : 'add');
        });
    });
})(jQuery);


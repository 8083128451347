/*
    -- @ MAIN-MENU @ --
    * Main menu javascript
*/

(function ($) {

    $(function () {
        /*
            -- @ MAIN MENU PHONE @ --
            * Show the hamburger button for main menu on phones
        */
        $('.open-menu button').on('click', function (e) {
            e.preventDefault();

            // Close all Header dropdowns (phone, contact, user account...)
            _closeDropdown();

            if ($(this).hasClass('is-active')) {
                $('html, body').removeClass('opened-menu');
                $(this).removeClass('is-active');
            } else {
                $('html, body').addClass('opened-menu');
                $(this).addClass('is-active');
            }
        });

        $('header').on('mouseleave', function () {
           if($(this).hasClass('submenu-shown')) {
               $(this).find('#main-nav a.js-open-sub-menu.shown').trigger('click');
           }
        });

        // Open sub-menus on phone
        // , #footer-menu .title
        $(document).on('click', '#main-nav a.js-open-sub-menu', function (e) {
            e.preventDefault();
            e.stopPropagation();

            var
                targeted = $(this),
                nextUl = targeted.parent().find(' > .inside-menu'),
                header = $('#main-header'),
                parentUl = targeted.parents('ul').first();

            $(parentUl).find('> li > a.js-open-sub-menu.shown')
                .not(targeted)
                .removeClass('shown');

            $(parentUl).find('> li > .inside-menu.shown')
                .not(nextUl)
                .removeClass('shown');

            $('#main-header').removeClass('submenu-small');

            if (nextUl.hasClass('shown')) {
                targeted.removeClass('shown');
                nextUl.removeClass('shown');
            } else {
                targeted.addClass('shown');
                header.addClass('submenu-shown');

                if (nextUl.hasClass('small')) {
                    header.addClass('submenu-small');
                }
                nextUl.addClass('shown');
            }

            if ($('#main-nav > ul > li > a.js-open-sub-menu.shown').length == 0) {
                $('#main-header').removeClass('submenu-shown');
            }
        });

        $('#main-nav .comptoir-item').on('mouseenter', function () {
            $('#main-nav .comptoir-item.active').removeClass('active');
            $(this).addClass('active');
        });

        // Close third level sub-menus on phone
        $(document).on('click', '.js-close-sub-menu', function (e) {
            e.preventDefault();

            var _parent = $(this).parents('.comptoir-item').find('.inside-menu');

            if (_parent.hasClass('shown')) {
                _parent.removeClass('shown');
            } else {
                _parent.addClass('shown');
            }
        });

    });
})(jQuery);

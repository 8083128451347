var fishContainer = document.getElementById('aquarium');
var NFish = 30;
var fishes = [];


function rand(min, max) {
    return ((Math.random() * (max - min)) + min).toFixed(2);
}

function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

var FishCreator = function(i){
    this.id = i;
    this.color = getRandomColor();
    this.size = rand(.2,1);
    this.y = rand(-10,85) + '%';
    this.time = rand(8,16) + 's';
    this.delay = rand(1,10) + 's';
    this.mirror = this.getMirror();
    this.order = this.size * 100;
    this.html = `<div class="movement ${this.mirror}" style="animation-delay: ${this.delay};animation-duration: ${this.time};z-index: -${this.order}; top: ${this.y};"><div id="fish" class="number-${this.id}" style="z-index: -${this.order}; color: ${this.color}; transform: scale(${this.size});"><div class="body" style="background: ${this.color}"><div class="child"></div></div><div class="fucking-flipper"><div class="flipper-mini"></div></div><div class="face"></div></div></div>`;
};

FishCreator.prototype.getMirror = function() {
    if (Math.floor(rand(0, 2)) === 1){
        return "ltr";
    } else {
        return "rtl";
    }
};


if(typeof(fishContainer) != 'undefined' && fishContainer != null) {

    var pepe = new FishCreator;

    for (var i = 0; i < NFish; i++) {
        var fish = new FishCreator(fishes.length);
        fishes.push(fish);
        fishContainer.innerHTML += fish.html;
    }
}


/*
    -- @ FLICKITY @ --
    * @version : 2.1.2
    * Make sliders cool cool
    * Documentation : https://flickity.metafizzy.co/
    * Used in ...
*/


$(function () {
    // .trip-carousel must be in a global var cuz to ajax relaod on search page !
    /*
        _launchtripCarousel() is called :
            - ~l.200
            - trip-detail.js ~l.97 et 215
    */
    _launchtripCarousel = function (element) {

        element.each(function (i, el) {
            var
                arrowShape = {
                    x0: 10,
                    x1: 60, y1: 50,
                    x2: 65, y2: 45,
                    x3: 20
                },
                speed = $(el).data('speed'),
                align = $(el).data('align');
            inifinteScroll = $(el).data('infinite') || false;


            var options = {
                wrapAround: inifinteScroll,
                imagesLoaded: true, // Unloaded images have no size, which can throw off cell positions. To fix this, the imagesLoaded option re-positions cells once their images have loaded.
                lazyLoad: 1,

                // Player
                autoPlay: speed, // Move each x miliseconds
                pauseAutoPlayOnHover: true, // Pause on hover ?

                // Slider animations
                selectedAttraction: 0.08, // Animation speed
                friction: 0.7,

                // Arrows & dots
                pageDots: false,
                arrowShape: arrowShape,

                watchCSS: true,
                cellAlign: align,
                contain: true,
                groupCells: true
            }


            // Init Flickity
            var $tripCarousel = $(el).flickity(options);
            $tripCarousel.flickity('resize')

            // Data
            // var flkty = $tripCarousel.data('flickity');

            // When we click on arrow, it make the slider in pause. So, launch player after clicking
            $tripCarousel.on('select.flickity', function () {
                $tripCarousel.flickity('playPlayer');
            });


            $(el).parent().find('.wrap-loader').remove();
            $(el).addClass('shown');
        });
    }


    $(function () {


        // Defined common flickity vars
        var
            arrowShape = {
                x0: 10,
                x1: 60, y1: 50,
                x2: 65, y2: 45,
                x3: 20
            }

        /*
            * HOMEPAGE FLICKITY CAROUSELS
        */
        // Flickity on Homepage : search form
        if ($(".home-carousel").length) {
            launchHomeCarrousels(arrowShape);
            addEventListener("resize", (event) => {
                launchHomeCarrousels(arrowShape);
            });
        }

        if ($(".custom-trip-carousel").length) {
            launchCustomTripHomeCarrousels(arrowShape);
            addEventListener("resize", (event) => {
                launchCustomTripHomeCarrousels(arrowShape);
            });
        }

        // Guarantee departures carousel + "Ses coups de coeur" in member detail page
        if ($('.guaranteed-departures-carousel').length) {

            var $GDCarousel = $('.guaranteed-departures-carousel').flickity({
                wrapAround: false, // Infinite horizontal scroll ?
                imagesLoaded: true, // Unloaded images have no size, which can throw off cell positions. To fix this, the imagesLoaded option re-positions cells once their images have loaded.
                lazyLoad: 2,
                contain: true,

                // Player
                autoPlay: false, // Move each x miliseconds
                pauseAutoPlayOnHover: true, // Pause on hover ?

                // Slider animations
                selectedAttraction: 0.08, // Animation speed
                friction: 0.7,

                percentPosition: false, // Sets positioning in percent values, rather than pixel values. If your cells do not have percent widths, we recommended percentPosition: false.

                prevNextButtons: ($('.guaranteed-departures-carousel .carousel-cell').length > 3) ? true : false,
                // Arrows
                arrowShape: arrowShape
            });

            // When we click on arrow, it make the slider in pause. So, launch player after clicking
            // $GDCarousel.on('select.flickity', function () {
            //     $GDCarousel.flickity('playPlayer');
            // });
        }
        // Guarantee departures carousel + "Ses coups de coeur" in member detail page
        if ($('.last-minutes-carousel').length) {

            var $LMCarousel = $('.last-minutes-carousel').flickity({
                // wrapAround: true, // Infinite horizontal scroll ?
                imagesLoaded: true, // Unloaded images have no size, which can throw off cell positions. To fix this, the imagesLoaded option re-positions cells once their images have loaded.
                lazyLoad: 2,
                draggable: true,
                contain: true,
                cellAlign: 'left',

                // Player
                autoPlay: false, // Move each x miliseconds
                pauseAutoPlayOnHover: true, // Pause on hover ?

                percentPosition: true, // Sets positioning in percent values, rather than pixel values. If your cells do not have percent widths, we recommended percentPosition: false.

                prevNextButtons: true,
                pageDots: false,
                // groupCells: (currentWindowWidth > 1024) ? 3 : ((currentWindowWidth > 576) ? 2 : 1),

                // Arrows
                arrowShape: arrowShape
            });

            // When we click on arrow, it make the slider in pause. So, launch player after clicking
            // $LMCarousel.on('select.flickity', function () {
            //     $GDCarousel.flickity('playPlayer');
            // });
        }
        // Flickity on Homepage : Testimonials
        if ($('.home-testimonials-carousel').length) {

            var $TCarousel = $('.home-testimonials-carousel').flickity({
                wrapAround: true, // Infinite horizontal scroll ?
                cellAlign: 'left',
                imagesLoaded: true, // Unloaded images have no size, which can throw off cell positions. To fix this, the imagesLoaded option re-positions cells once their images have loaded.

                // Player
                autoPlay: 5000, // Move each x miliseconds
                pauseAutoPlayOnHover: true, // Pause on hover ?

                // Slider animations
                selectedAttraction: 0.08, // Animation speed
                friction: 0.7,

                // Arrows
                arrowShape: arrowShape,
                pageDots: false,

                groupCells: (currentWindowWidth > 1024) ? 2 : 1,
                // Adaptative Height
                adaptiveHeight: true
            });

            // When we click on arrow, it make the slider in pause. So, launch player after clicking
            $TCarousel.on('select.flickity', function () {
                $TCarousel.flickity('playPlayer');
            });
        }


        /*
            * TRIP-LISTING CAROUSELS
            * Used in :
                - TRIP LISTING : carousel on each trip
                - TRIP-DETAIL CAROUSEL : top of page with infos right
                - TRIP-DETAIL CAROUSEL : Stage pictures on mobile
                - CUSTOM TRIP MAIN PAGE CAROUSEL : sur la page "Voyage sur mesure" dans le menu principal
                - ACCOUNT : Loved trip carousel on each trip
        */
        // Flickity on trip-listing page && Trip Detail page
        if ($('.trip-carousel').length) {
            _launchtripCarousel($('.trip-carousel'));
        }


        /*
            * CROSS-SELLING CAROUSEL
            * Used in :
                - Trip detail page "Vous aimerez aussi"
                - Member detail page "Ses coups de coeur"
        */
        if ($('.crossselling-carousel').length) {

            setTimeout(function () {
                var $CSCarousel = $('.crossselling-carousel').flickity({
                    wrapAround: (currentWindowWidth > XLWidthMin) ? false : true, // Infinite horizontal scroll ?
                    imagesLoaded: true, // Unloaded images have no size, which can throw off cell positions. To fix this, the imagesLoaded option re-positions cells once their images have loaded.
                    cellAlign: 'center',
                    groupCells: (currentWindowWidth > XLWidthMin) ? 3 : 1,
                    // Player
                    autoPlay: 5000, // Move each x miliseconds
                    pauseAutoPlayOnHover: true, // Pause on hover ?

                    // Slider animations
                    selectedAttraction: 0.08, // Animation speed
                    friction: 0.7,

                    percentPosition: false, // Sets positioning in percent values, rather than pixel values. If your cells do not have percent widths, we recommended percentPosition: false.

                    // Arrows
                    pageDots: false,
                    prevNextButtons: ($('.crossselling-carousel .carousel-cell').length > 3) ? true : false,
                    arrowShape: arrowShape
                });

                // When we click on arrow, it make the slider in pause. So, launch player after clicking
                $CSCarousel.on('select.flickity', function () {
                    $CSCarousel.flickity('playPlayer');
                });
            }, 400);
        }


        /*
            * CMS TIRAWA CAROUSEL
        */
        // Flickity for Others pages in "Tirawa" pages
        if ($('.others-pages-carousel').length) {

            var $CMSCarousel = $('.others-pages-carousel').flickity({

                contain: true, // Prevent Infinite scroll
                prevNextButtons: false, // Hide arrows
                watchCSS: true,
                // Player
                autoPlay: 5000, // Move each x miliseconds
                pauseAutoPlayOnHover: true, // Pause on hover ?

                // Slider animations
                selectedAttraction: 0.08, // Animation speed
                friction: 0.7
            });

            // When we click on arrow, it make the slider in pause. So, launch player after clicking
            $CMSCarousel.on('select.flickity', function () {
                $CMSCarousel.flickity('playPlayer');
            });
            // When slider is loaded, hide loader and show slider
            $('.wrap-others-pages-carousel .wrap-loader').fadeOut('fast', function () {
                $(this).remove();
                $CMSCarousel.addClass('shown');
            });
        }
    });
});

var $homePageTripCarousel;

function launchHomeCarrousels(arrowShape) {
    if ($('.home-carousel:visible').length) {

        try {
            $('.home-carousel').flickity('destroy');
        } catch (e) {
            // console.log(e);
        }

        var $homeCarousel = $('.home-carousel').flickity({
            wrapAround: true, // Infinite horizontal scroll ?
            imagesLoaded: true, // Unloaded images have no size, which can throw off cell positions. To fix this, the imagesLoaded option re-positions cells once their images have loaded.
            lazyLoad: 1,
            fade: true,

            // Player
            autoPlay: 5000, // Move each x miliseconds
            pauseAutoPlayOnHover: true, // Pause on hover ?

            // Slider animations
            selectedAttraction: 0.08, // Animation speed
            friction: 0.7,
            pageDots: false,
            prevNextButtons: false,

            // Arrows
            arrowShape: arrowShape
        });

        // When we click on arrow, it make the slider in pause. So, launch player after clicking
        $homeCarousel.on('select.flickity', function () {
            $homeCarousel.flickity('playPlayer');
        });
    }

    if (window.innerWidth < 1024) {
        $homePageTripCarousel = $('#trip-type-carousel').flickity({
            wrapAround: true, // Infinite horizontal scroll ?
            lazyLoad: 1,
            cellAlign: 'left',

            // Player
            autoPlay: 5000, // Move each x miliseconds
            pauseAutoPlayOnHover: true, // Pause on hover ?

            // Slider animations
            selectedAttraction: 0.08, // Animation speed
            friction: 0.7,
            pageDots: false,
            prevNextButtons: true,

            // Arrows
            arrowShape: arrowShape
        });
    } else {
        if ($homePageTripCarousel) {
            $homePageTripCarousel.flickity('destroy');
            $homePageTripCarousel = null;
        }
    }
}

var $homeCustomTripCarrousels;

function launchCustomTripHomeCarrousels(arrowShape) {
    if (window.innerWidth < 426) {
        $homeCustomTripCarrousels = $('.custom-trip-carousel').flickity({
            wrapAround: true, // Infinite horizontal scroll ?
            lazyLoad: 1,
            cellAlign: 'left',

            // Player
            autoPlay: 5000, // Move each x miliseconds
            pauseAutoPlayOnHover: true, // Pause on hover ?

            // Slider animations
            selectedAttraction: 0.08, // Animation speed
            friction: 0.7,
            pageDots: false,
            prevNextButtons: true,

            // Arrows
            arrowShape: arrowShape
        });
    } else {
        if ($homeCustomTripCarrousels) {
            $homeCustomTripCarrousels.flickity('destroy');
            $homeCustomTripCarrousels = null;
        }
    }
}

(($) => {
    $(() => {

        let onResearchRoutes = onPath('/voyage-*');

        if (!(onPath('/') || onResearchRoutes)) {
            return;
        }

        /**
         * You can handle Research.fillInput by adding a callback with fill key
         *
         * @type {*[]}
         */
        let fields = [
            ["research_form[price][]", {"term": "prix"}],
            ["research_form[difficulty][]", {"term": "niveau"}],
            ["research_form[comfort][]", {"term": "confort"}],
            ["research_form[duration][]", {"term": "duree"}],
            ["research_form[type][]", {"term": "type"}],
            ["research_form[status][]", {"term": "statut"}],
            ["research_form[country][]", {"term": "pays"}],
            ["research_form[regions][]", {"term": "regions"}],
            ["research_form[periods][]", {"term": "periods"}]
        ];

        let trip = new Research(fields);
        let $form = $('form[name="research_form"]');
        let crappyDuplicateAvoider;

        $('.cc-loading').removeClass("cc-loading");

        $form.on('change', '[type="checkbox"]', (ev) => {

            let $element = $(ev.currentTarget);
            let field = trip.getFieldBy('name', $element.attr('name'));

            if (field !== null) {
                let value = $element.val();

                if ($element.prop('checked')) {
                    trip.addParameter(field.term, value);
                } else {
                    trip.removeParameter(field.term, value);
                }

                displayInputLabel($element);
                _showLoading();
                //
                if (onResearchRoutes) {
                    if (crappyDuplicateAvoider !== undefined) {
                        clearTimeout(crappyDuplicateAvoider);
                    }
                    crappyDuplicateAvoider = setTimeout(() => {
                        $form.trigger('trip.research.updated');
                    }, 500);
                }
            }
            ev.stopPropagation();
        });

        $form.on('change', '[type="select"]', (ev) => {

            let $element = $(ev.currentTarget);
            let field = trip.getFieldBy('name', $element.attr('name'));

            if (field !== null) {
                let value = $element.val();
                console.log(value);

                if ($element.prop('selected')) {
                    trip.addParameter(field.term, value);
                } else {
                    trip.removeParameter(field.term, value);
                }

                displayInputLabel($element);
                _showLoading();
                //
                if (onResearchRoutes) {
                    if (crappyDuplicateAvoider !== undefined) {
                        clearTimeout(crappyDuplicateAvoider);
                    }
                    crappyDuplicateAvoider = setTimeout(() => {
                        $form.trigger('trip.research.updated');
                    }, 500);
                }
            }
            ev.stopPropagation();
        });

        // $form.find('[type="checkbox"]').trigger('change');

        $(window).on('dropdown-closed', () => {
            trip = new Research(fields);
            $form.find('[type="checkbox"]').trigger('change');
            $form.find('select').trigger('change');
            // $form.trigger('trip.research.updated');
        });

        /**
         * Specific behaviour for home (input country)
         */
        if (!onResearchRoutes) {
            let countryDefinition = trip.getFieldBy('name', 'research_form[country][]');
            let typeDefinition = trip.getFieldBy('name', 'research_form[type][]');

            $form.on('change', 'select', (ev) => {
                let $element = $(ev.currentTarget);
                trip.removeParameter('pays');
                if ($element.val() != "") {
                    trip.addParameter('pays', $element.val());

                    displayInputLabel($element);

                    $form.trigger('trip.research.updated');
                    ev.stopPropagation();
                }
            });
            $form.find('select').trigger('change');


            $form.on('submit', ev => {
                ev.preventDefault();

                let country = trip.getParameter(countryDefinition.term) || 0;
                let types = trip.getParameter(typeDefinition.term) || 0;

                let url = '';

                if (country.length === 1) { // Statement for specific url trip_research_continent_country
                    let $tag = $('[name="research_form[country]"] option[value="' + country[0] + '"]');
                    let parameters = [
                        $tag.data('slug'),
                        $tag.data('counter-slug')
                    ];

                    // counter-slug can be missing
                    if (parameters.length === 2) {
                        url = Routing.generate('trip_research_continent_country', {
                            "country": parameters[0],
                            "counter": parameters[1]
                        }, true);

                        // Remove to exclude from query parameter
                        trip.removeParameter(countryDefinition.term);
                    }

                } else if (types.length === 1) { // Statement for specific url trip_research_type
                    let $tag = $('[name="research_form[type][]"][value="' + types[0] + '"]');

                    if ($tag.data('slug') == 'new') {
                        url = Routing.generate('trip_research_type_new', {}, true);
                    } else {
                        url = Routing.generate('trip_research_type', {
                            "type": $tag.data('slug'),
                        }, true);
                    }

                    // Remove to exclude from query parameter
                    trip.removeParameter(typeDefinition.term);
                }

                if (url === '') {
                    url = Routing.generate('trip_research', {}, true);
                }

                window.location.href = url + trip.getQuery();
            });
        }

        if (onResearchRoutes) {
            /**
             * Handle fetchUrlParameters to provide extra parameters.
             * This following statement provide region or type parameter if exists.
             */
            trip.fetchUrlParameters(segments => {
                let addParameterSlug = (term, prefix, parameter) => {
                    let name = trip.getFieldBy('term', term, true);
                    let slug = parameter.replace(prefix, '');
                    let value = null;
                    let $inputs = $('[name="' + name + '"]');

                    $inputs.each((index, element) => {
                        let $element = $(element);

                        if ($element.data('slug') === slug) {
                            value = $element.val();

                            // @todo Lock field when came from specific url cc-disabled doesn't work fixme
                            $element.parents('.form-item.dropdown').addClass('cc-disabled');


                        }
                    });

                    if (value) {
                        trip.addParameter(term, value);
                        displayInputLabel($inputs);
                    }

                    return value;
                };

                if (segments.length === 2) {
                    addParameterSlug('pays', 'circuits-', segments[1]); // From url /voyages-{continent}/circuits-{pays}
                } else if (segments[0] !== 'voyages-recherches') {
                    addParameterSlug('type', 'voyages-', segments[0]); // From url /voyages-{type}
                }
            });

            trip.fillInputsFromFetchedParameters(name => {
                $('[name="' + name + '"]').each((index, element) => {
                    displayInputLabel($(element));
                });
            });

            let xhr = null;

            $form.on('trip.research.updated', () => {
                trip.updateUrl();

                _showLoading();

                if (xhr !== null) {
                    xhr.abort();
                }

                xhr = $.post(document.location.href, $form.serialize());

                $.when(xhr).then(response => {
                    $form.trigger('trip.research.results', response);
                });
            });

            let displayNbResults = (nb) => {
                let $ctx = $('.country-rating-total');

                // Reset
                $ctx.find('.result-message').addClass('cc-hidden');
                $('body').find('.result-nb').text('');

                // Apply new changes
                switch (parseInt(nb)) {
                    case 0:
                    case 1:
                        $ctx.find('.nb-' + nb).removeClass('cc-hidden');
                        break;
                    default:
                        $ctx.find('.nb-more').removeClass('cc-hidden');
                }

                // Change number in <span class="result-nb"></span>
                $('body').find('.result-nb').text(nb);
                $('.js-close-listing-filters').css({"display": "block"});
                $('.js-loader-listing-filters').hide();

                if ($ctx.find('.plurial').length) {
                    if (nb > 1)
                        $ctx.find('.plurial').text('voyages');
                    else
                        $ctx.find('.plurial').text('voyage');
                }
            };

            $form.on('trip.research.results', (event, response) => {
                if (response.hasOwnProperty('content')) {

                    $('div.country-list .contain-trip-results').html(response.content);

                    displayNbResults(response.nb_results);

                } else {

                    var _htmlNoResults = '<div class="no-results"><p class="first">Oh, oh, on dirait bien qu’aucun' +
                        ' résultat ne correspond à vos critères de recherche...</p><p>&nbsp;</p><p>Voici nos' +
                        ' suggestions :</p><p><strong>Revoir vos critères,</strong> il suffit parfois de peu pour' +
                        ' trouver le voyage de ses rêves.</p><p><strong>Nous contacter par téléphone,</strong> une' +
                        ' équipe aussi sympathique que compétente est à votre disposition du lundi au vendredi de 9h' +
                        ' à 12h30.</p><p>S\'il est trop tôt ou trop tard, <strong>écrivez-nous' +
                        ' !</strong> Nous nous ferons un plaisir de vous aider.</p><p>&nbsp;</p><p><a href="javascript:void(0);" class="btn cc-bg-black modal-open" data-target="modal-contact">Nous contacter</a></p></div>';

                    $('div.country-list  .contain-trip-results').html(_htmlNoResults);

                    displayNbResults(0);
                }

                _hideLoading();
            });


            $('.js-reset-filters').on('click', function (ev) {
                // window.location.href = Routing.generate('trip_research', {}, true);
                $form.find(':checkbox').prop("checked", false).trigger('change');
                $form.find('.form-item.dropdown .label-like').trigger('click').parent().trigger('mouseleave');
                $('body .current-shown-dropdown-container').remove();
                setTimeout(function () {
                    $(window).trigger('dropdown-closed');
                }, 200);
            });
        }
    })
})(jQuery);


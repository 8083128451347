(($) => {
    $(() => {
        let $ctx = $('form[name="customer_quotation_form"]');
        let callbackQuotationForm = () => {
            $('.wrap-plus-minus-btns').inputQtty();

            $("#voyage-sur-mesure").find('.js-flatpickr').flatpickr({
                minDate: "today",
                dateFormat: "d.m.Y",
                locale: 'fr'
            });

            let $inputNumberAdults = $('input#customer_quotation_form_number_adults');

            let participantPrototype = $('#customer_quotation_form_participants').data('prototype');

            let $wrapParticipants = $('.wrap-participants');

            let newParticipant = (index) => {
                var widget = participantPrototype;

                widget = widget.replace(/__name__/g, index);
                widget = widget.replace(/label__/g, ' ° participant');


                var $element = $(widget);
                $element.find('.form-item').addClass('cc-inline');


                return $element.appendTo($wrapParticipants);
            };

            // Add participant element to DOM
            $inputNumberAdults.on('app.qtty-plus.after', (ev, value) => {
                newParticipant(value);
            });

            // Remove last participant element from DOM
            $inputNumberAdults.on('app.qtty-minus.after', (ev, value) => {
                $wrapParticipants.children().slice(-1).remove();
            });

            // Add or remove participant elements from DOM (depend the value)
            $inputNumberAdults.on('app.qtty-change', (ev, value) => {
                if (value <= 0) {
                    $wrapParticipants.empty();

                    return;
                }

                var itemsLength = $wrapParticipants.children().length;
                var diff = -1 * (itemsLength - value);

                if (diff === 0) {
                    return;
                }

                if (diff > 0) {
                    for (; diff > 0; diff--) {
                        newParticipant(++itemsLength);
                    }

                    return;
                }

                $wrapParticipants.children().slice(diff).remove();
            });


            let $inputTrip = $('#customer_quotation_form_trip');

            $('#customer_quotation_form_trip_reference').val($inputTrip.find(':selected').data('reference'));

            $inputTrip.on('change', ev => {
                let $this = $(ev.currentTarget);

                $('#customer_quotation_form_trip_reference').val($this.data('reference'));
            });

            // Prev / Next step
            $(document).on('click', '.stepper .js-change-step, .js-nav-tabs .step-done a', function (e) {
                e.preventDefault();

                var
                    _this = $(this);

                // Click on stepper buttons
                if (_this.hasClass('js-change-step')) {
                    var
                        _rel = _this.data('rel'),
                        _form = _this.parents('form'),
                        _nav = $('.js-nav-tabs'),
                        _activeLi = _nav.find('li[data-rel="' + _rel + '"]'),
                        _indexLi = _nav.find('li').index(_activeLi);
                }
                // click on tabs buttons
                else {
                    var
                        _parent = _this.parent(),
                        _rel = _parent.data('rel'),
                        _form = $('form.quotation'),
                        _nav = $('.js-nav-tabs'),
                        _activeLi = _parent,
                        _indexLi = _nav.find('li').index(_parent);
                }

                // Fieldsets display
                _form.find('fieldset').removeClass('active');
                $(_rel).addClass('active');

                // Navigation display
                // _nav.find('li').removeAttr('class');
                // Highlight the current step with class 'active'
                _activeLi.addClass('active');
                // Switch previous steps with class 'step-done'
                _nav.find('li:lt(' + _indexLi + ')').addClass('step-done');

                // ScrollTo
                var _finalPosition = parseInt($('.wrap-custom-trip-quotation-form').offset().top - 85);

                $('html, body').stop(true, true).animate({scrollTop: _finalPosition}, 350, function () {
                });
            });

            // Disabled keypress "ENTER" when a user is filling the quotation form.. ENTER == NO SUBMIT
            $('form.quotation').on('keypress', function (e) {

                var x = e.which || e.keyCode;

                if (x == 13 && $('form.quotation').find('*:focus').length) {
                    e.preventDefault();
                }
            });

            // Check if inputs are filled or not
            var checkInputsInForm = function (form) {
                var data = {status: true, inputs: []};

                var gender = form.find('#customer_quotation_form_gender').val();

                if (gender == "Madame" && $('#customer_quotation_form_maiden_name').val() == "") {
                    $('#customer_quotation_form_maiden_name').addClass('error');
                    data.status = false;
                    data.inputs.push($('#customer_quotation_form_maiden_name'));
                } else {
                    $('#customer_quotation_form_maiden_name').removeClass('error');
                }

                // Loop on each form-item container
                form.find('.form-item').each(function (i, el) {
                    var _formItem = $(this);

                    // Required radio inputs
                    if (_formItem.find('[type="radio"][required]').length > 0) {
                        if (_formItem.find('[required]:checked').length > 0) {
                            _formItem.removeClass('error')
                        } else {
                            _formItem.addClass('error');
                            data.inputs.push(_formItem);
                            data.status = false;
                        }
                    } else {

                        // Loop on each input
                        _formItem.find('[required]').each(function (i, el) {

                            var _input = $(this);

                            if (_input.attr('type') == 'checkbox') {

                                if (_input.is(':checked')) {
                                    _formItem.removeClass('error')
                                } else {
                                    data.status = false;
                                    data.inputs.push(_formItem);
                                    _formItem.addClass('error')
                                }
                            } else {

                                // CHECK DATES HERE
                                // ALPIXEL MICK
                                $('#customer_quotation_form_date_start').parent().removeClass('error');

                                var wantedDay = $('#customer_quotation_form_date_start_day').val();
                                var wantedMonth = $('#customer_quotation_form_date_start_month').val();
                                var wantedYear = $('#customer_quotation_form_date_start_year').val();
                                var today = new Date();
                                var wantedDate = new Date(wantedYear, (wantedMonth - 1), wantedDay);

                                if(today > wantedDate) {
                                    $('#customer_quotation_form_date_start').parent().addClass('error');
                                    data.status = false;
                                    data.specialMsg = 'Merci de renseigner une "date de départ souhaitée" supérieure à aujourd\'hui.';
                                    data.inputs.push($('#customer_quotation_form_date_start'));
                                }


                                if ($.trim(_input.val()) != '') {
                                    _formItem.removeClass('error')
                                } else {
                                    data.status = false;
                                    data.inputs.push(_formItem);
                                    _formItem.addClass('error')
                                }
                            }
                        })
                    }
                });

                return data;
            };

            $(document).on('submit', '.trip-detail form.js-validate', function (e) {
                e.preventDefault();
                var _form = $(e.currentTarget);
                var check = checkInputsInForm(_form);

                if (!check.status) {
                    $('.step-1, .step-2, .step-3').removeClass('active');
                    check.inputs[0].parents('fieldset').addClass('active');

                    if(check.specialMsg !== undefined) {
                        swalert('error', 'Erreur !', check.specialMsg);
                    } else {
                        swalert('error', 'Erreur !', 'Votre demande ne peut être envoyée car certains champs ne sont pas remplis correctement. Merci de vérifier les informations que vous avez renseigné, puis de soumettre une nouvelle fois votre demande de devis.');
                    }



                } else {
                    let $form = $(this);
                    $.post($(this).attr('action'), $(this).serialize(), function (data) {

                        if (data.validated != undefined && data.validated == true) {

                            swalert("success", "Votre demande de devis a bien été envoyé", "Nos équipes reviendront vers vous dans les plus brefs délais.");

                            // TAG YUMENS
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                                'event': 'privatization-form-success',
                                'product_name': $form.attr('data-name'),
                                'product_id': $form.attr('data-reference'),
                                'product_category': $form.attr('data-category'),
                                'product_country': $form.attr('data-country')
                            });
                            // TAG YUMENS


                        } else {
                            $("#voyage-sur-mesure").html(data);
                            callbackQuotationForm();
                        }
                    });
                }
            });
        };


        $(document).on('app.xhr.done.trip_tab_tailored_trip', ev => {
            let $this = $(ev.currentTarget);

            callbackQuotationForm();
        });

        if (!$ctx.length) {
            return;
        }

        callbackQuotationForm();

        // Use custom jquery function in global.js

    });
})(jQuery);

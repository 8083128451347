/*
    -- @ FORM @ --
    * All forms into the website are controled here
*/

(function ($) {
    $(function () {

        /*
            * Transform an input value in uppercase (lastname, ) - TIRAWA-220
        */

        // Return an upper string
        function setUpper(str) {
            return str.toUpperCase();
        }

        // Return the first letter of a string in upper
        function capitalizeFirstLetter(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }

        $(document).on('change', '.to-upper', function () {
            $(this).val(setUpper($(this).val()));
        });
        $(document).on('change', '.to-ucfirst', function () {
            $(this).val(capitalizeFirstLetter($(this).val()));
        });

        /*
            * SHOW/HIDE PASSWORD by clicking on eye icon
        */
        $(document).on('click', 'a.js-show-pass', function (e) {
            e.preventDefault();

            var
                _this = $(this),
                _input = _this.parent().find('input'),
                _eye = 'ion-eye',
                _eye_disabled = 'ion-eye-disabled';

            if (_input.attr('type') == 'text') {
                _input.attr('type', 'password');
                _this.find('i').attr('class', _eye);
            } else {
                _input.attr('type', 'text');
                _this.find('i').attr('class', _eye_disabled);
            }
        });


        /*
            * Show/hide Forget password form
        */
        $(document).on('click', '.js-forgot-pass', function (e) {
            var
                _login_form = $('form.login-form'),
                _pass_form = $('form.forget-password-form');

            if (_login_form.hasClass('cc-hidden')) {
                _login_form.removeClass('cc-hidden');
                _pass_form.addClass('cc-hidden');
            } else {
                _login_form.addClass('cc-hidden');
                _pass_form.removeClass('cc-hidden');
            }
        });


        /*
            -- @ HOMEPAGE - SEARCH FORM @ --
            ** Show / Hide Search form on Homepage
        */
        // ** MOBILE : Show / Hide Search form on Homepage
        $(document).on('click', '.js-close-home-search-form, .js-open-search-form', function (e) {
            e.preventDefault();

            // Close all Header dropdowns (phone, contact, user account...)
            _closeDropdown();

            var _form = $('.wrap-home-search-form');


            if (_form.hasClass('shown')) {

                // Hide form
                _form.removeClass('shown');
                $('.js-open-search-form').addClass('shown');
                $('html, body').removeClass('opened-menu');

            } else {

                // Show form
                _form.addClass('shown');

                // fix body and html to prevent scroll
                $('html, body').addClass('opened-menu');

                // Hide search button
                $('.js-open-search-form').removeClass('shown');
            }
        });

        $(document).on('click', '.js-open', function (e) {
            e.preventDefault();
            var target = $($(this).data('target'));
            if (target != undefined && target.length > 0) {
                target.toggleClass('shown');
            }
        });

        /*
            -- @ TRIP-LISTING - FILTERS FORM @ --
            ** Show / Hide Filters form on trip-listing page
            ** Show / Hide loader when filtering
        */
        // ** Show / Hide Search form on Homepage
        $(document).on('click', '.js-open-listing-filters, .js-close-listing-filters', function (e) {
            e.preventDefault();

            // Close all Header dropdowns (phone, contact, user account...)
            _closeDropdown();

            var _form = $('.wrap-listing-filters');


            if (_form.hasClass('shown')) {

                // Hide form
                _form.removeClass('shown');
                // remove fix body and html to prevent scroll
                $('html, body').removeClass('opened-menu');
            } else {

                // Show form
                _form.addClass('shown');

                // fix body and html to prevent scroll
                $('html, body').addClass('opened-menu');
            }
        });

        if ($('.reviews-form-items').length) {
            let callbackReviewsProp = function ($element, $target) {
                if ($element.is(':checked')) {
                    $target.prop('checked', 'checked');
                    $target.prop('checked', true);
                } else {
                    $target.prop('checked', false);
                    $target.removeProp('checked');
                }
            };


            $(document).on('change', '.reviews-form-items input', function (e) {
                const _this = $(this);
                const _value = $.trim(_this.val());
                let $target = _this;

                // If "ALL COUNTRIES"
                if (_value === '*') {
                    $target = $('.reviews-form-items input');
                }
                // Si on coche un comptoir, on coche tous les pays associés au comptoire
                else if (_value.indexOf('.js-comptoir-') === 0) {
                    $target = $(_value).find('input');
                }

                callbackReviewsProp(_this, $target);
                displayInputLabel($target);

            });

            let getCountriesReviewsFromUrl = () => {
                let href = document.location.href;

                const baseUri = '/avis-voyageurs/';
                const index = href.indexOf(baseUri);

                if (index !== -1) {
                    return [href.substr(index + baseUri.length)];
                }

                return document.location.search.split(/\??&?pays%5B%5D=/).filter(a => a.length);
            };

            const $ctx = $('.reviews-form-items');

            if (window.location.href === Routing.generate('survey_all_reviews', {}, true)) {
                $ctx.find('input[type="checkbox"]').prop('checked', true);

                return;
            }

            let $checkboxes = $('.comptoir-countries input[type="checkbox"]');
            let countries = getCountriesReviewsFromUrl();

            // Check input from fetched countries
            $checkboxes.each((index, element) => {
                countries.filter(country => {
                    let $element = $(element);

                    if ($element.val() === country) {
                        $element.prop('checked', true);
                        displayInputLabel($element);

                        return true;
                    }

                    return false;
                });
            });

            $('.js-comptoir-entry').each((index, element) => {
                let $element = $(element);
                let $target = $($element.val());
                let $checkboxes = $target.find('input[type="checkbox"]');
                let $checked = $target.find('input[type="checkbox"]:checked');

                if ($checkboxes.length === $checked.length) {
                    $element.prop('checked', true);
                    displayInputLabel($element);
                }
            });
        }

        $('form.reviews-form').one('submit', function (e) {
            e.preventDefault();
            e.stopPropagation();

            const $ctx = $('.reviews-form-items');
            let isAllChecked = false;

            // Clean some data
            $ctx.find('[name="pays[]"]:checked').each(function () {
                let $element = $(this);
                if ($element.val() === '*') {
                    isAllChecked = true;

                    return;
                }

                if ($element.val().indexOf('.js-comptoir') !== -1) {
                    $element.prop('checked', false);
                    $element.removeProp('checked');
                }
            });

            const $checked = $ctx.find('[name="pays[]"]:checked');

            if (isAllChecked) {
                window.location.href = Routing.generate('survey_all_reviews', {}, true);
            } else if ($checked.length === 1) {
                const $slug = $checked.val().split("_").pop();
                window.location.href = Routing.generate('survey_country_reviews', {slug: $slug}, true);
            } else {
                $checked.map(index => {
                    let $element = $($checked[index]);

                    if ($element.hasClass('js-comptoir-entry')) {
                        $element.prop('checked', false);
                        $element.removeProp('checked');
                    }
                });

                $(this).submit();
            }
        });
    });
})(jQuery);

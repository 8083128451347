(function ($) {
    $(function () {
        $('.payment-multiple-trigger-link').on('click', function (e) {
            if ($(this)[0].hasAttribute('data-disabled')) {
                e.preventDefault();
                return;
            }

            $(this).html('<i class="fas fa-spinner fa-pulse"> En attente de votre paiement...');
            $(this).attr('data-disabled', 'disabled');
        });
    });
})
(jQuery);

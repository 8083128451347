(($) => {
    $(() => {
        // This file serve the trip-detail page (trip_show) for tab navigation (Jour par jour, Détail prix, ...)
        // Some of this tab is loaded from ajax and others is directly loaded in page for SEO impact

        let $ctx = $('body.trip-detail .wrap-tab-content');

        if (currentWindowWidth < 1440) {
            $('#scroll-tabs a.active').removeClass('active');
        }

        if ($ctx.length) {
            const tripSlug = $('#scroll-tabs').data('slug');

            let cached = {};
            let xhr = null;

            /**
             * Load content from ajax and fill section with response
             * The variable cached is used to store response for current lifetime page
             *
             * @param tab string
             * @param route string
             * @param parameters object
             * @param callback function
             */
            let xhrLoadContent = (tab, route, parameters, callback) => {
                if (xhr !== null) {
                    xhr.abort();
                }

                if (typeof callback !== 'function') {
                    callback = () => {
                    };
                }

                $('#scroll-tabs').find('a[href*="' + tab + '"]').addClass("loading");
                var $tab = $ctx.find('section' + tab);

                if (cached.hasOwnProperty(tab)) {
                    callback(true, cached[tab]);
                    $("#scroll-tabs a.loading").removeClass("loading");
                    window.scrollTo($tab.offset().left, $tab.offset().top);

                    return;
                }

                xhr = $.get(Routing.generate(route, parameters))
                    .done((response) => {
                        cached[tab] = response;
                        $tab.html(response);

                        if (tab === '#voyage-sur-mesure') {
                            $ctx.find('section' + tab).trigger('app.xhr.done.trip_tab_tailored_trip');
                        }

                        callback(true, response);

                    })
                    .fail((response) => {
                        callback(false, response);
                    })
                    .always((response) => {
                        $("#scroll-tabs a.loading").removeClass("loading");

                        //Scroll to this tab
                        window.scrollTo($tab.offset().left, $tab.offset().top);
                    });
            };

            /**
             * The keys of definedTabs are the a tag in #scroll-tabs
             * These keys are use to set the anchor in browser.
             *
             * A callback key can be set to call ajax function or another process, before display the section
             */
            const definedTabs = {
                '#jour-par-jour': null,
                '#detail-prix': {
                    'callback': (callback) => {
                        let routeName = "trip_tab_detail_price";
                        if (ALPIXEL.app.isTailorMade != undefined) {
                            routeName = "custom_" + routeName;
                        }
                        xhrLoadContent('#detail-prix', routeName, {slug: tripSlug}, callback)
                    }
                },
                '#infos-pratiques': {
                    'callback': (callback) => {
                        let routeName = "trip_tab_practical_info";
                        if (ALPIXEL.app.isTailorMade != undefined) {
                            routeName = "custom_" + routeName;
                        }
                        xhrLoadContent('#infos-pratiques', routeName, {slug: tripSlug}, callback)
                    }
                },
                '#extensions': {
                    'callback': (callback) => {
                        let routeName = "trip_tab_extensions";
                        if (ALPIXEL.app.isTailorMade != undefined) {
                            routeName = "custom_" + routeName;
                        }
                        xhrLoadContent('#extensions', routeName, {slug: tripSlug}, callback)
                    }
                },
                '#voyage-sur-mesure': {
                    'callback': (callback) => {
                        xhrLoadContent('#voyage-sur-mesure', 'trip_tab_tailored_trip', {slug: tripSlug}, callback)
                    }
                },
                '#avis-voyageurs': null,
            };

            let getTab = (anchor) => {
                if (definedTabs.hasOwnProperty(anchor)) {
                    return definedTabs[anchor];
                }
            };


            /**
             * @param $tag Must be the a jquery object of $('a.js-change-tab')
             */
            let changeTab = ($tag) => {
                let anchor = $tag.attr('href');

                if (anchor.indexOf('#') !== 0) {
                    let anchorTable = _.split(anchor, "#");
                    anchor = '#' + anchorTable.pop();
                }

                let tab = getTab(anchor);
                let targetTab = $('#scroll-tabs').find('a[href*="' + anchor + '"]');

                if (tab !== null && tab.hasOwnProperty('callback')) {
                    console.log('oui');
                    tab.callback();
                } else {
                    console.log('non')
                    //Scroll to this tab
                    window.scrollTo(targetTab.offset().left, targetTab.offset().top);
                }

                // Change current anchor in browser
                window.location.hash = anchor.replace('#', '');


                // Hide all sections
                $('div.wrap-tab-content section').addClass('cc-hidden').removeClass('active');

                // Remove active class to all tab links
                $('#scroll-tabs').find('a').removeClass('active');

                // Show targeted section
                $(anchor).addClass('active').removeClass('cc-hidden');

                // Active current tag
                targetTab.addClass('active');
                $ctx.addClass('shown');
                $ctx.find(".panel-title span").text($tag.text());
                $("body").addClass('trip-tab-shown');

            };

            // When we are the first time on the page
            let defaultAnchor = (window.location.hash.length > 0) ? window.location.hash : null;
            if (defaultAnchor !== null) {
                changeTab($('#scroll-tabs a[href*="' + defaultAnchor + '"]'));
            }

            // Listen for change tab
            $(document).on('click', 'a.js-change-tab', function (ev) {
                ev.preventDefault();
                ev.stopPropagation();

                //Prevent scroll to hidden tab
                var x = window.pageXOffset,
                    y = window.pageYOffset;
                $(window).one('scroll', function () {
                    if (currentWindowWidth < 1440) {
                        window.scrollTo(x, y);
                    }
                })

                if (!$(ev.currentTarget).hasClass('active')) {
                    changeTab($(ev.currentTarget));
                }

                return false;
            });

            $('.wrap-tab-content .close-panel').on('click', function (e) {
                e.preventDefault();
                $ctx.removeClass('shown');
                $("body").removeClass('trip-tab-shown');
                $('#scroll-tabs').find('a').removeClass('active');
            });
        }
    })
})(jQuery);


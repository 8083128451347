/*
    -- @ FLATPICKR @ --
    * @version : 4.0.6
    * Make datepicker cool cool
    * Documentation : https://chmln.github.io/flatpickr/
*/



(function ($) {
    $(function () {
        var defaultOptions = {
            dateFormat: "d.m.Y",
            locale: 'fr',
            mode: 'single'
        };

        $('.js-flatpickr-simple').add('[data-calendar="js-flatpickr-simple"]').flatpickr({
            dateFormat: "d.m.Y",
            locale: "fr"
        });

        // Ce voyage sur mesure : Choix de la date de départ souhaitée
        $('.js-flatpickr').flatpickr(defaultOptions);

        // Flatpickr on homepage - Search form, date input
        $('.js-date-flatpickr').flatpickr(Object.assign(defaultOptions, {mode: "range"}));

        $('.js-flatpickr-birthdate').flatpickr(Object.assign(defaultOptions, {
            mode: 'single',
            minDate: (function () {
                var date = new Date();
                date.setFullYear(date.getFullYear() - 100);

                return date;
            }),
            maxDate: (function () {
                var date = new Date();
                date.setFullYear(date.getFullYear() - 18);
                return date;
            })
        }));

        $('.js-flatpickr-passport-emission').flatpickr(Object.assign(defaultOptions, {
            mode: 'single',
            dateFormat: "d.m.Y",
            locale: 'fr',
            minDate: (function () {
                //Now minus 11 years
                var date = new Date();
                date.setFullYear(date.getFullYear() - 11);
                return date;
            }),
            maxDate: (function () {
                //Now plus 11 years
                var date = new Date();
                return date;
            })
        }));

        $('.js-flatpickr-passport-validity').flatpickr(Object.assign(defaultOptions, {
            mode: 'single',
            dateFormat: "d.m.Y",
            locale: 'fr',
            minDate: (function () {
                var date = new Date();
                return date;
            }),
            maxDate: (function () {
                //Now plus 11 years
                var date = new Date();
                date.setFullYear(date.getFullYear() + 11);
                return date;
            })
        }));
    });
})(jQuery);

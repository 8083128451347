/**
 * This class MUST NOT required jQuery only vanilla Javascript (>= ES6)
 *
 * Class Research try to be less related to trip research try to keep that logic.
 * Field parameter is a definition of the inputs name and his associated term (url)
 *
 * Most important functions are these following functions:
 *
 * fetchUrlParameters assign term and his values in this.parameters a callback can be use to provide more parameters
 * fillInput mark field as checked by default or using the provided callback
 */
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

class Research {

    /**
     * @param fields
     */
    constructor(fields) {

        /**
         * First index of array is the name of input, second object with term to use (fill function optional)
         * Example of fields definition:
         *     [
         *        ["research_form[price][]", {"term": "prix"}],
         *        ["research_form[difficulty][]", {"term": "niveau"}],
         *        ["research_form[comfort][]", {"term": "confort"}]
         *     ]
         */
        if (!Array.isArray(fields)) {
            throw new Error('"fields" parameter must be an array.');
        }

        /**
         * @type {Map}
         */
        this.parameters = new Map();

        /**
         * @type {Map} Contain objects {name: 'name of input (DOM)', term: 'key to use in research' }
         */
        this.fields = new Map(fields);
    }

    /**
     * @param {function} callback splitted and trimmed url segment
     */
    fetchUrlParameters(callback = null) {
        let pathname = window.location.pathname.replace('/app_dev.php', '').replace(/^\/|\/$/g, '');
        let segments = pathname.split('/');

        if (typeof callback === 'function') {
            callback(segments);
        }

        // assign query term to this.parameters
        if (window.location.search.length > 0) {
            window.location.search.replace(/^\?/g, '').split('&').forEach(plainParameter => {
                let parameter = plainParameter.split('=');
                let values = (() => {
                    if (parameter[1] !== 'undefined') {
                        return decodeURI(parameter[1]).split(',');
                    }

                    return [];
                })();

                this.addParameter(parameter[0], values);
            });
        }
    }

    /**
     * @param name string Content of attribute name of input (DOM)
     * @param value mixed The value belong to the associated input
     */
    fillInput(name, value) {
        let field = this.getFieldBy('name', name);

        if (field === null) {
            return;
        }

        let elements = document.getElementsByName(name);

        for (let i = 0; i < elements.length; i++) {
            let element = elements[i];
            if (element.value === value) {
                if (field.hasOwnProperty('fill') && typeof field.fill === 'function' && field.fill(element, value)) {
                    return;
                }

                switch (element.type) {
                    case 'select':
                        element.selected = true;
                        break;
                    case 'checkbox':
                        element.checked = true;
                        break;
                }
            }
        }
    }

    fillInputs(name, values) {
        values.forEach(value => {
            this.fillInput(name, value);
        });
    }

    fillInputsFromFetchedParameters(callback) {
        this.fields.forEach((obj, name) => {
            let values = this.getParameter(obj.term);

            if (values === null) {
                return;
            }

            this.fillInputs(name, values);

            if (typeof callback === 'function') {
                try {
                    callback(name, values, obj);
                } catch (error) {
                    console.error(error);
                }
            }
        });
    }

    /**
     * @param property
     * @param key
     * @param returnName
     * @returns {object, string, null}
     */
    getFieldBy(property, key, returnName = false) {
        for (let [name, obj] of this.fields) {
            if ((property === 'name' && name === key) || (property === 'ter' && obj.term === key)) {
                return returnName ? name : obj;
            }
        }

        return null;
    }

    /**
     * Add parameter to this.parameters
     * @param name
     * @param value
     */
    addParameter(name, value) {
        if (!Array.isArray(value)) {
            value = [value];
        }

        if (!this.parameters.has(name)) {
            this.parameters.set(name, []);
        }

        let values = this.parameters.get(name);

        if (values.indexOf(value) === -1) {
            this.parameters.set(name, values.concat(value));
        }
    }

    /**
     * @returns {Array}|null
     */
    getParameter(name) {
        return this.parameters.get(name) || null;
    }

    /**
     * @param name
     * @param valueToFind if omitted the parameter is deleted, if value is present but is the
     *        last element in values entry the parameter is deleted
     */
    removeParameter(name, valueToFind) {
        if (this.parameters.has(name)) {
            let index = this.parameters.get(name).indexOf(valueToFind);

            if (index !== -1) {
                this.parameters.get(name).splice(index, 1);
            }

            if (this.parameters.get(name).length === 0 || typeof valueToFind === 'undefined') {
                this.parameters.delete(name);
            }
        }
    }

    clearParameters() {
        this.parameters.clear();
    }

    /**
     * Iterate over this.parameters to update url
     */
    updateUrl() {
        let url = window.location.pathname + this.getQuery();
        url = url.replace(/page=\d+/, 'page=1');

        window.history.pushState(
            null,
            null,
            url
        );
    }

    /**
     * @returns {string}
     */
    getQuery() {
        let terms = [];
        let query = '';

        for (let [key, values] of this.parameters.entries()) {
            let segment = key;

            if (values.length > 0) {
                segment += '=' + values.map(o => {
                    return encodeURI(o);
                }).join(',');
            }

            terms.push(segment);
        }

        if (terms.length > 0) {
            query = '?' + terms.join('&');
        }

        return query;
    }
}

(function ($) {
    $(function () {

        $('.inspirations-list a.js-deploy-all').on('click', function (e) {
            e.preventDefault();
            $(this).parent().find('.quotation-btn-wrapper').removeClass('cc-hidden');
            $(this).remove();
        });

        $('.tailor-made-modal-open').on('click', function (e) {
            e.preventDefault();

            $('#modal-tailor-made-choice').addClass('active');
        });

        $('.trigger-tailor-made-inquiry, .trigger-tailor-made-appointment').on('click', function (e) {

            $('#modal-tailor-made-choice').removeClass('active');
            let $this = $(this);
            let isInquiry = $this.hasClass('trigger-tailor-made-inquiry');
            let formDestinationsField;
            let form_name = isInquiry ? 'tailor_made_quotation_form' : 'tailor_made_appointment_form';
            let uri = isInquiry ? "contactez-nous" : "rendez-vous";

            formDestinationsField = '#' + form_name + '_destinations';
            if ($('.tailor-made-modal-open').attr('data-tailor-made-country')) {
                uri += "/" + $('.tailor-made-modal-open').attr('data-tailor-made-country');
            }
            if ($('.tailor-made-modal-open').attr('data-tailor-made-trip')) {
                uri += "/" + $('.tailor-made-modal-open').attr('data-tailor-made-trip');
            }

            $('#modal-tailor-made-form').addClass('active');
            $.get("/voyage-sur-mesure/" + uri, function (data) {
                displayForm(data, isInquiry);
                adjustMinBudgetPrice(isInquiry);
                $(formDestinationsField).on('change', function () {
                    adjustMinBudgetPrice(isInquiry);
                });
            });

            function adjustMinBudgetPrice(isInquiry) {
                var countries = [];
                var minBudget = 3000;
                var form_name = isInquiry ? 'tailor_made_quotation_form' : 'tailor_made_appointment_form';

                var budgetField = $('#' + form_name + '_budget');

                if (typeof quotationCountry !== 'undefined') {
                    quotationMinBudgetPrices.forEach(function (element) {
                        if (quotationCountry == element.id) {
                            minBudget = element.minimumBudget;
                        }
                    });

                    budgetField.attr('min', minBudget);
                    $(budgetField).val(minBudget);
                    $('.slider-value').text(minBudget + ' €');
                } else if ($('#' + form_name + '_destinations option:selected').length > 0) {
                    $('#' + form_name + '_destinations option:selected').each(function () {
                        countries.push(parseInt($(this).val()));
                    });

                    quotationMinBudgetPrices.forEach(function (element) {
                        if (countries.indexOf(element.id) > -1 && element.minimumBudget > minBudget) {
                            minBudget = element.minimumBudget;
                        }
                    });

                    if (budgetField.val() < minBudget) {
                        $(budgetField).val(minBudget);
                        $('.slider-value').text(minBudget + ' €');
                    }
                    budgetField.attr('min', minBudget);
                } else {
                    budgetField.attr('min', minBudget);
                    $(budgetField).val(minBudget);
                    $('.slider-value').text(minBudget + ' €');
                }

            }

            function displayForm(data, isInquiry) {
                $('#modal-tailor-made-form .wrap-form').html(data.html);

                $('#modal-tailor-made-form form').on('submit', function (e) {
                    e.preventDefault();

                    $('#modal-tailor-made-form').find('.form-actions button').addClass('cc-loading');

                    var $form = $(this);
                    $.post("/voyage-sur-mesure/" + uri, $(this).serialize(), function (data) {

                        $('#modal-tailor-made-form').find('.form-actions button').removeClass('cc-loading');


                        if (data.status == "error") {
                            displayForm(data);
                            setTimeout(
                                () => tippy('form .tippyjs'),
                                500
                            );

                        } else {
                            $('#modal-tailor-made-form').removeClass('active');
                            if (isInquiry) {
                                swalert('success', 'Merci !', 'Votre demande de devis a bien été envoyée.<br/>Notre équipe vous répondra dans les meilleurs délais.');
                            } else {
                                swalert('success', 'Merci !', 'Votre demande de rendez-vous a bien été enregistrée.<br/>Vous allez recevoir une confirmation par email.');
                            }
                            if (isInquiry) {
                                var duration = $form.attr('data-duration');
                                if (duration == undefined || duration == "") {
                                    duration = $('#tailor_made_quotation_form_duration').val()
                                }

                                // TAG YUMENS : Tracking sur mesure form
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    'event': 'custom-trip-form-success',
                                    'product_name': $form.attr('data-name'),
                                    'product_id': $form.attr('data-reference'),
                                    'custom_trip_duration': duration,
                                    'product_country': $form.attr('data-country')
                                });
                                // TAG YUMENS
                            }
                        }
                    });
                });

                //Gestion des enfants
                var form_name = isInquiry ? 'tailor_made_quotation_form' : 'tailor_made_appointment_form';

                if (isInquiry) {
                    var childrenContainer = document.querySelector('#tailor_made_quotation_form_childrenAges');
                    var childrenNbField = document.querySelector('#tailor_made_quotation_form_nbChildren');
                    childrenNbField.addEventListener('change', function () {

                        var nbChildren = 0;
                        if (childrenNbField.value != '') {
                            childrenContainer.parentNode.classList.remove('cc-hidden');
                            nbChildren = parseInt(childrenNbField.value);
                        } else {
                            childrenContainer.parentNode.classList.add('cc-hidden');
                        }

                        for (i = 0; i < 5; i++) {
                            var item = childrenContainer.querySelectorAll('.form-item')[i];
                            if (i < nbChildren) {
                                item.classList.remove('cc-hidden');
                            } else {
                                item.classList.add('cc-hidden');
                                item.querySelector('input').value = '';
                            }
                        }
                    });
                    childrenNbField.dispatchEvent(new Event('change'));
                }
                //Fin de gestion des enfants

                //Gestion du budget
                var budgetField = document.querySelector('#' + form_name + '_budget');
                var sliderValue = budgetField.insertAdjacentElement('afterend', document.createElement('div'));
                sliderValue.classList.add('slider-value');
                budgetField.addEventListener('input', function () {
                    if (parseInt(budgetField.value) === 20000) {
                        sliderValue.textContent = budgetField.value + ' € et +';
                    } else {
                        sliderValue.textContent = budgetField.value + ' €';
                    }
                });
                budgetField.dispatchEvent(new Event('input'));
                //Fin gestion budget

                //Gestion des pays et proposition de voyage
                if (isInquiry) {
                    var countryField = document.querySelector('#tailor_made_quotation_form_destinations');
                    var tripField = document.querySelector('#tailor_made_quotation_form_trip');
                    if (countryField !== null) {
                        countryField.addEventListener('change', function () {
                            var selectedCountries = [];
                            countryField.querySelectorAll('option:checked').forEach(function (option) {
                                selectedCountries.push(option.value);
                            });

                            tripField.querySelector('option').selected = 'selected';
                            if (selectedCountries.length > 0) {
                                tripField.querySelectorAll('option').forEach(function (option) {
                                    if (selectedCountries.indexOf(option.getAttribute('data-country')) > -1) {
                                        option.classList.remove('cc-hidden');
                                    } else {
                                        option.classList.add('cc-hidden');
                                    }
                                });
                            } else {
                                tripField.querySelectorAll('option').forEach(function (option) {
                                    option.classList.remove('cc-hidden');
                                });
                            }

                            if (tripField.querySelectorAll('option:not(.cc-hidden)').length > 0) {
                                tripField.parentNode.classList.remove('cc-hidden');
                            } else {
                                tripField.parentNode.classList.add('cc-hidden');
                            }

                        });
                    }
                }
            }
        });
    });
})(jQuery);

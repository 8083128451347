/*
    -- @ TRIP DETAIL @ --
    * All JS scripts for trip detail page
*/

(function ($) {

    $(function () {

        if ($('.trip-detail').length) {
            function showHideQuickActions() {
                //get Y offset of the #trip-content div
                var tripContentOffset = $('#trip-content').offset().top;

                //get Y offset of current scroll position
                var currentScrollPosition = $(window).scrollTop() + $(window).height();

                if (currentScrollPosition > tripContentOffset) {
                    $('#quick-actions').addClass('shown');
                } else {
                    $('#quick-actions').removeClass('shown');
                }
            }

            $('.header-big-image .reviews').on('click', function () {
               $('#scroll-tabs').find('a[href$="#avis-voyageurs"]').trigger('click');
            });

            showHideQuickActions();
            //on scroll => showHideQuickActions
            $(window).scroll(function () {
                showHideQuickActions();
            });

            /*
                -- @ BOOTSTRAP AFFIX @ --
                * Used in :
                    - trip-detail page : Tabs fixed when scrolling into the detail page
            */
            var
                substract = (currentWindowWidth >= LWidthMin) ? 65 : 130,
                wrapper = $('.wrapper'),
                presRightWrapper = $('.trip-presentation-right');

            if ($('.trip-presentation-right .trip-link-map').length > 0) {
                var mapOffsetTop = $('.trip-presentation-right .trip-link-map').offset().top;
            }

            // // Affix
            // wrapper.affix({
            //     offset: {
            //         top: tabsOffsetTop
            //     }
            // });
            // presRightWrapper.affix({
            //     offset: {
            //         top: mapOffsetTop
            //     }
            // });


            // Hide "Carte" Button when contact bloc appears
            $('.wrap-trip-contact').bind('appear', function (e) {
                $('.trip-presentation-right').removeClass('affix').addClass('affix-top')
            });

            /*
                -- @ Infos Pratiques @ --
            */
            $(document).on('click', '.informations-list .label a', function (e) {
                e.preventDefault();
                $(this).parent().parent().toggleClass('active')
            });


            /*
                -- @ Extensions slideUp and slideDown @ --
            */

            // Open / Close details btn
            $(document).on('click', '.js-open-extension-detail, .js-close-extension-detail', function (e) {
                e.preventDefault();

                var
                    _this = $(this),
                    _rel = _this.data('rel');

                // If relative element exists
                if ($(_rel).length) {

                    var
                        _openBtn = $(_rel).find('.trip-infos .open-extension-detail'),
                        _closeBtn = $(_rel).find('.trip-infos .close-extension-detail'),
                        _infosBox = $(_rel).find('.wrap-extensions-infos');


                    // If infos are hiden
                    if (_infosBox.hasClass('is-open')) {
                        _infosBox.removeClass('is-open').stop(true, false).slideUp();
                        _openBtn.show();
                        _closeBtn.hide();
                    } else {
                        _infosBox.addClass('is-open').stop(true, false).slideDown();
                        _openBtn.hide();
                        _closeBtn.show();

                        // Reload Flickity !
                        var $carousel = _infosBox.find('.trip-carousel');
                        if ($carousel.length)
                            _launchtripCarousel($carousel);


                        // Scroll
                        var _finalPosition = parseInt(_infosBox.offset().top - 100);

                        $('html, body').stop(true, true).animate({scrollTop: _finalPosition}, 350, function () {
                        });
                    }
                }
            });

        } // /end - if($('.trip-detail').length)


        // Spécifi for Listings Tailor made trips
        if ($('body.custom-trip.all-trip').length > 0) {
            inView('.wrap-gir')
                .on('enter', el => {
                    $('body').addClass('scrolled-gir')
                })
                .on('exit', el => {
                    $('body').removeClass('scrolled-gir')
                });
            inView.offset(450);
        }


        // Load Reviews when clicking to "Charger plus d'avis"
        var xhrMoreReviews = null;
        var page = 0;
        var displaySurveys = function (error, data, $ctx, pageMax) {
            if (error) {
                swalertCommonError();
            } else {
                $(data).appendTo($('.wrap-reviews'));

                if (page === pageMax) {
                    $ctx.remove(); // No more data to load ! We remove the button
                }
            }

            $ctx.removeClass('cc-loading');
        };

        var xhrDownloadForm;
        $('.trip-link-download').on('click', function (e) {
            var slug = $('a[data-target="modal-download"]').attr('data-slug');
            var program = $('a[data-target="modal-download"]').attr('data-program');

            e.preventDefault();
            if (xhrDownloadForm != undefined) {
                xhrDownloadForm.abort();
            }

            if (program != undefined) {
                var route = Routing.generate("trip_download_program", {
                    tripSlug: $(this).attr('data-slug'),
                    groupStage: $(this).attr('data-program')
                });
            } else {
                var route = Routing.generate("trip_download", {
                    tripSlug: $(this).attr('data-slug')
                });
            }

            xhrDownloadForm = $.get(route, function (data) {
                $('#modal-download').find('.js-wrap-form').html(data.html);
            });
        });

        $(document).on('submit', '#modal-download form', function (e) {
            e.preventDefault();
            var slug = $('a[data-target="modal-download"]').attr('data-slug');
            var program = $('a[data-target="modal-download"]').attr('data-program');

            if ($(this).attr('action') != "" && $(this).attr('action') != undefined) {
                var route = $(this).attr('action');
            } else {
                if (program != undefined) {
                    var route = Routing.generate("trip_download_program", {
                        tripSlug: slug,
                        groupStage: program
                    });
                } else {
                    var route = Routing.generate("trip_download", {
                        tripSlug: slug
                    });
                }
            }
            var formData = $(this).serialize();
            var $form = $(this);

            $('#modal-download form button').addClass('cc-loading');

            xhrDownloadForm = $.post(route, formData, function (data) {

                $('#modal-download form button').removeClass('cc-loading');

                if (data.submitted === false) {
                    $('#modal-download').find('.js-wrap-form').html(data.html);
                } else {
                    window.dataLayer = window.dataLayer || [];

                    window.dataLayer.push({
                        event: 'download-form-success'
                    });

                    setTimeout(function () {
                        var newWin = window.open(data.link);

                        if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                            document.location.href = data.link;
                        }

                        $('#modal-download').find('[data-close-modal]').trigger('click');
                    }, 500);
                }
            });
        });


        $('.trip-link-tailor-made-download').on('click', function (e) {
            var slug = $('a[data-target="modal-download"]').attr('data-slug');

            e.preventDefault();
            if (xhrDownloadForm != undefined) {
                xhrDownloadForm.abort();
            }

            var route = Routing.generate("custom_trip_download", {
                tripSlug: $(this).attr('data-slug')
            });

            xhrDownloadForm = $.get(route, function (data) {
                $('#modal-download').find('.js-wrap-form').html(data.html);
            });
        });


        $(document).on('click', 'a[data-program]', function (e) {
            e.preventDefault();

            var
                $this = $(this),
                $program = $this.data('program'),
                $festivalWrapper = $('.stage-by-stage-multiple[data-program=' + $program + ']');

            // If DOM element does not exists, stop
            if ($festivalWrapper.length == 0)
                return;

            // Hide all stage by stage
            $('.stage-by-stage-multiple').removeClass('shown');

            // Show targeted program
            $festivalWrapper.addClass('shown');

            // Reload Flickity !
            var $carousel = $festivalWrapper.find('.trip-carousel');
            if ($carousel.length)
                _launchtripCarousel($carousel);


            // Scroll
            var _finalPosition = parseInt($festivalWrapper.offset().top - 150);
            $('html, body').stop(true, true).animate({scrollTop: _finalPosition}, 350, function () {
            });
        });

        let currentReviewPage = 1;
        $('.js-show-reviews').click(function () {

            let $wrapReviews = $('.wrap-reviews');
            currentReviewPage++;

            $wrapReviews.find('.review').slice(0, currentReviewPage * 10).css('display', 'block');
            if ($wrapReviews.find('.review').length <= currentReviewPage * 10) {
                $(this).remove();
            }
        });
    });
})(jQuery);

/*
    -- @ RESERVATION @ --
    * JS for reservation pages
*/

(function ($) {

    var phoneFields = [
        "#reservation_informations_phone",
        "#reservation_informations_mobile",
        "#reservation_informations_urgencyPhone",
    ];

    for (var i = 2; i <= 10; i++) {
        phoneFields.push("#reservation_travellers_otherPassengers_" + i + "_phone");
        phoneFields.push("#reservation_travellers_otherPassengers_" + i + "_mobile");
        phoneFields.push("#reservation_travellers_otherPassengers_" + i + "_urgencyPhone");
    }

    $(document).on('mouseover', '.icon-helper', function (e) {
        $(this).addClass('icon-label-shown');
    });

    $(document).on('mouseleave', '.icon-helper', function (e) {
        $(this).removeClass('icon-label-shown');
    });

    function resetMask() {
        $('.js-flatpickr-passport-emission').flatpickr({
            mode: 'single',
            dateFormat: "d.m.Y",
            locale: 'fr',
            minDate: (function () {
                //Now minus 11 years
                var date = new Date();
                date.setFullYear(date.getFullYear() - 11);
                return date;
            }),
            maxDate: (function () {
                //Now plus 11 years
                var date = new Date();
                return date;
            })
        });

        $('.js-flatpickr-passport-validity').flatpickr({
            mode: 'single',
            dateFormat: "d.m.Y",
            locale: 'fr',
            minDate: (function () {
                var date = new Date();
                return date;
            }),
            maxDate: (function () {
                //Now plus 11 years
                var date = new Date();
                date.setFullYear(date.getFullYear() + 11);
                return date;
            })
        });

        phoneFields.forEach(function (field) {
            var input = document.querySelector(field);

            if (input != null) {
                window.intlTelInput(input, {
                    initialCountry: "fr",
                    // initialCountry: "auto",
                    // geoIpLookup: function (callback) {
                    //     fetch("https://ipapi.co/json")
                    //         .then(function (res) {
                    //             return res.json();
                    //         })
                    //         .then(function (data) {
                    //             callback(data.country_code);
                    //         })
                    //         .catch(function () {
                    //             callback("fr");
                    //         });
                    // },
                    utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@19.2.16/build/js/utils.js",
                });
            }
        });

    }

    resetMask();

    // Add / Remove a participant onto quotation form
    var bindAddCollectionItemHandler = function () {
        $('.js-prototype-add').on('click', function (e) {
            e.preventDefault();
            doAddCollectionItem($(this).data('js-prototype'));
        });
    };


    var doAddCollectionItem = function (collectionName) {

        var
            _newItem = $('.js-prototype-model[data-js-prototype="' + collectionName + '"] .prototype-line'),
            _container = $('.js-prototype-container[data-js-prototype="' + collectionName + '"]'),
            _length = _container.children().length + 2;


        var _clone = _newItem.clone(true);
        _clone = $(_clone.html().replace(/__name__/g, _length));


        // Append HTML into wrapper
        _clone.appendTo(_container)


        // Launch Flatpickr
        _clone.find('input.js-flatpickr-simple').flatpickr({
            dateFormat: "d.m.Y",
            locale: "fr"
        });
    };

    var bindRemoveCollectionItemHandle = function () {

        $(document).on('click', '.js-prototype-delete', function (e) {
            e.preventDefault();

            var
                _this = $(this),
                _collectionName = _this.data('js-prototype'),
                _container = $('.js-prototype-container[data-js-prototype="' + _collectionName + '"]'),
                _line = _this.closest('.prototype-line-item');


            _line.fadeOut(400, function () {
                _line.remove();

                // Change travelers numbers (ça évite d'avoir des "trous" dans les numéros des voyageurs supplémentaires
                var _nbTravelers = $('.js-prototype-container').find('.prototype-line-item').length;

                if (_nbTravelers > 0) {
                    for (var i = 1; i <= _nbTravelers; i++) {


                        $('.prototype-line-item:eq(' + i + ')').find('.prototype-name').text(i + 1);
                    }
                }
            });


        });
    };


    // Show / Hide fields regarding element value
    // Ex. Show/hide maidenName if Gender is "Madam"
    var showHideFieldsByBinding = function () {

        $(document).on('change', '*[data-bind]', function (e) {
            var
                _input = $(this),
                _value = _input.val(),
                _rel = _input.data('bind'),
                _container = $('[data-binded=' + _rel + ']');

            if (!$(_container).length)
                return;


            // Si c'est un <select>
            if (_input.is('select')) {
                if (_value == 'Madame')
                    _container.show();
                else
                    _container.hide()

            }
            // Si c'est un inpuy checkbox
            else if (_input.is('input[type="checkbox"]')) {
                if (_input.is(':checked'))
                    _container.hide();
                else
                    _container.show()
            }

        });

        $("*[data-bind]").trigger('change');
    };

    $(function () {
        bindAddCollectionItemHandler();
        bindRemoveCollectionItemHandle();

        // Show or Hide fields regarding input with data-bind attribute
        showHideFieldsByBinding();

        // Registration : Walla cette function add the primero voyageur automatisch quand the page is loaded
        if ($('.reservation-side.step-3').length) {
            $('.js-prototype-add').trigger('click');
            setTimeout(function () {
                resetMask();
            }, 1000);
        }


        /*
            * Close / Open Reservation details on Mobile
        */
        $('.js-reservation-detail').on('click', function (e) {
            e.preventDefault();

            var _panel = $('.reservation-content-right');

            if (_panel.hasClass('shown')) {
                _panel.removeClass('shown');
                $('html,body').removeClass('opened-modal');
            } else {
                _panel.addClass('shown');
                $('html,body').addClass('opened-modal')
            }
        });

        $('.reservation-side .reservation-content-left form').on('submit', function (e) {
            if (!$(this).hasClass("submitting")) {
                $(this).addClass('submitting');
            } else {
                e.preventDefault();
            }
        });

        $('#reservation_options_departureCity').on('change', function (e) {
            e.preventDefault();
            if ($(this).val() == "Autre ville") {
                $('#reservation_options_otherDepartureCity').show();
            } else {
                $('#reservation_options_otherDepartureCity').val("").hide();
            }
        });
        $('#reservation_options_departureCity').trigger('change');

        $('#reservation_option_informations_departureCity').on('change', function (e) {
            e.preventDefault();
            if ($(this).val() == "Autre ville") {
                $('#reservation_option_informations_otherDepartureCity').show();
            } else {
                $('#reservation_option_informations_otherDepartureCity').val("").hide();
            }
        });
        $('#reservation_option_informations_departureCity').trigger('change');


        $('.fakeDepartureField').on('change', function (e) {
            e.preventDefault();
            if ($(this).val() == "Autre ville") {
                $(this).parent().find('.departureField').val("").show();
            } else {
                $(this).parent().find('.departureField').val($(this).val()).hide();
            }
        });
        $('.fakeDepartureField').trigger('change');


        $('.trigger-insurance').on('change', function (e) {
            var parent = $(this).parent().parent();
            if ($(this).val() == 'noInsurance' || $(this).val() == 'insuranceComplementary') {
                parent.find('[data-trigger]').closest(".form-item").show();
            } else {
                parent.find('[data-trigger]').closest(".form-item").hide();
                parent.find('[data-trigger]').val("");
            }
        });

        $('.trigger-insurance').trigger('change');

        if ($('body').hasClass('reservation')) {
            loadSummaryTable();


            $('.js-prototype-add[data-js-prototype="participant"]').unbind("click");
            $('.js-prototype-add[data-js-prototype="participant"]').on('click', function (e) {
                // e.preventDefault();
                TIRAWA.reservation.nbPassenger++;

                var max = $(this).attr('data-max');

                if (TIRAWA.reservation.nbPassenger > max) {
                    $('.js-prototype-add[data-js-prototype="participant"]').parent().hide();
                    $('.btns-sep').hide();
                }

                loadSummaryTable();
            });
            bindAddCollectionItemHandler();


            $(document).on('click', '.js-prototype-delete[data-js-prototype="participant"]', function (e) {
                TIRAWA.reservation.nbPassenger--;

                var max = $('.js-prototype-add[data-js-prototype="participant"]').attr('data-max');

                if (TIRAWA.reservation.nbPassenger <= max) {
                    $('.js-prototype-add[data-js-prototype="participant"]').parent().show();
                    $('.btns-sep').show();
                }

                loadSummaryTable();
            });

            $('#reservation-options').on('change', function (e) {
                TIRAWA.reservation.singleRoom = $('#reservation_options_singleRoom').val();
                TIRAWA.reservation.insuranceCasualty = $('#reservation_options_insuranceCasualty').is(':checked');
                TIRAWA.reservation.insuranceComplementary = $('#reservation_options_insuranceComplementary').is(':checked');
                TIRAWA.reservation.nbRapidVisa = 0;
                $('.rapid-visa-fields select').each(function (i, el) {
                    if ($(el).val() == "1") {
                        TIRAWA.reservation.nbRapidVisa++;
                    }
                });
                loadSummaryTable();
            });

            $('.payment-item').on('click', function (e) {
                var link = $(this);
                if (link.attr('data-type') != undefined) {
                    e.preventDefault();
                    Swal({
                        'title': "Confirmation",
                        'html': "Confirmez-vous votre paiement par " + $(this).attr("data-type") + " ?",
                        'type': "warning",
                        'showConfirmButton': true,
                        'showCancelButton': true,
                        'cancelButtonClass': 'cc-bg-black cc-pat-0 cc-pab-0',
                        'cancelButtonText': 'Fermer'
                    }).then((result) => {
                        if (result.value) {
                            document.location.href = link.attr("href");
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                        }
                    });
                }
            });
        }
    });


    var _currentSummaryrRequest;

    function loadSummaryTable() {
        var _wrapper = $("#reservation-summary-wrapper");


        if (_wrapper.length > 0) {

            var _params = TIRAWA.reservation;

            _showLoading();

            if (_currentSummaryrRequest !== undefined) {
                _currentSummaryrRequest.abort();
            }

            if (!$('body').hasClass('option-swiss')) {

                _currentSummaryrRequest = $.ajax({
                    url: Routing.generate("order_summary", _params)
                })
                    .done(function (data) {

                            _wrapper.html(data.data);


                            // Fill the price on mobile
                            var mobile_price = data.amount;
                            $('.reservation-total-total .js-bind').text(mobile_price);


                            _hideLoading()
                        }
                    );
            }
        }
    }

    var xhrExtension = null;


    $(document).on('click', '.js-extension-add', function (ev) {
        ev.preventDefault();

        var _this = $(this);

        _this.addClass('cc-loading');

        if (xhrExtension !== null) {
            _this.removeClass('cc-loading');
            xhrExtension.abort();
        }

        var url = Routing.generate('reservation_extension', {
            trip: $(this).data('trip-id'),
            extension: $(this).data('extension-id')
        });

        xhrExtension = $.post(url)
            .always(function (response, status) {

                _this.removeClass('cc-loading');

                if (status === 'error' || !response.isSuccess) {
                    swalertCommonError();
                    return;
                }

                swalert('success', 'Extension ajoutée', 'L\'extension a bien été ajoutée à votre voyage.');

            });
    })
})
(jQuery);

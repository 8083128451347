(($) => {
    $(() => {

        let loadForm = (xhr, url, callback) => {
            if (xhr !== null) {
                xhr.abort();
            }

            xhr = $.get(url)
                .done((data) => callback(false, data))
                .fail((error) => callback(error))
        };

        let xhr = null;
        let $modal = $('#modal-abuse');
        let urlReport = Routing.generate('survey_report');
        let $wrapForm = $modal.find('.wrap-form');

        $('.js-report-survey').on('click', (e) => {
            e.preventDefault();

            let $ctx = $(e.currentTarget);

            loadForm(xhr, urlReport, (error, data) => {
                if (error) {
                    swalertCommonError();

                    return
                }

                $modal.addClass('active');
                $wrapForm.html(data);
                $wrapForm.find('#report_survey_form_survey').val($ctx.data('survey-id'));
            });
        });

        $modal.on('submit', 'form', e => {
            e.preventDefault();

            let $ctx = $(e.currentTarget);

            $.post(urlReport, $ctx.serialize())
                .done(data => {
                    if (typeof data === 'string') {

                        $wrapForm.empty();

                        let $newHtml = $(data)

                        $wrapForm.append($newHtml)

                        setTimeout(
                            () => tippy('.wrap-form .tippyjs'),
                            500
                        );

                        return;
                    }

                    $modal.removeClass('active');

                    if (data.success) {
                        swalert('success', 'Merci !', 'Votre signalement nous a bien été envoyé, nous reviendrons vers vous dans les meilleurs délais');
                        return;
                    }

                    swalertCommonError();
                })
                .fail(error => swalertCommonError());
        });
    });
})(jQuery);


function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toGMTString();
    document.cookie = cname + "=" + cvalue + "; " + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
    }
    return "";
}

function checkCookie(cname) {
    var user = getCookie(cname);
    return (user != "") ? true : false;
}

function cleanPathname(pathname) {
    return pathname.replace('/app_dev.php', '').replace(/^\/|\/$/g, '');
}

function onPath(path) {
    if (!(Array.isArray(path) || typeof path === "string")) {
        throw new Error("Parameter path must be a type of array or string");
    }

    let currentPath = cleanPathname(window.location.pathname);
    let check = (pathname) => {
        return currentPath.match(cleanPathname(pathname).replace('/', '\/')) !== null;
    };

    if (Array.isArray(path)) {
        for (let pathname of path) {
            if (check(pathname)) {
                return true;
            }
        }

        return false;
    }

    return check(path);
}

function swalert(type, title, html, closeAfter) {
    if (typeof closeAfter !== 'number') {
        closeAfter = 8000;
    }

    swal({
        'title': title,
        'html': html,
        'type': type,
        'showConfirmButton': false,
        'showCancelButton': true,
        'cancelButtonClass': 'cc-bg-black cc-pat-0 cc-pab-0',
        'cancelButtonText': 'Fermer'
    });
}

function swalertCommonError() {
    swalert('error', 'Une erreur est survenue !', 'Une erreur s\'est produite lors de l\'action veuillez réessayer');
}

function displayInputLabel($input) {
    let $dropdown = $input.parents('.form-item.dropdown');

    if ($dropdown.length) {
        let $checked = $dropdown.find('input:checked');
        let $labelLike = $dropdown.find('.label-like');

        if ($checked.length === 0) {
            $labelLike.text($labelLike.data('label'));

            return;
        }

        if($checked.length === 1) {
            var _parentHTML = $checked.parent();
            var _nbI = 0;
            var _text = $.trim(_parentHTML.text());


            // Si "Niveau" ou "Confort", on affiche le nombre après le label
            if(_parentHTML.find('i') || _parentHTML.find('img')) {
                var _nbI = parseInt(_parentHTML.find('i').length) || parseInt(_parentHTML.find('img').length);

                if(_nbI > 0)
                    _text += ' ' + _nbI;
            }

            var _strLen = _text.length;

            // Truncate string
            if(_strLen > 10) {
                _text = _text.substring(0,8)+'...';
            }

            $labelLike.text(_text);
            return;
        }

        $labelLike.text($checked.length + ' sélections');
    }
}

function isEmail(email) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
}

